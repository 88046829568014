import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import HelpBar from "./HelpBar";
import BTravelList from "./BTravelList";

const StaffCommuting = () => {
  return (
    <>
      <HelpBar />
      <Box
        bgcolor={"background.default"}
        p={2}
        borderRadius={1}
        mt={4}
        minHeight={"100vh"}
      >
        <Box display={"flex"} flexDirection={"row-reverse"} mb={3}>
          <Typography variant="subtitle2" color="icon">
            Updated Just Now
          </Typography>
        </Box>
        <Box bgcolor="background.paper" p={4} mx={3} borderRadius={1}>
          <Typography mb={1}>
            How Many Percentage of average employee do daily commute for work?
          </Typography>
          <Typography mb={3}>
            Our System also account for Work From Home.
          </Typography>
          <Typography mb={3}>
            Dont include emplyee commte from Company vehicles here, only include
            private venhicles or public Transport
          </Typography>
          <TextField
            id="percentage-input"
            label="Enter Detail Here"
            autoFocus
            variant="outlined"
            defaultValue={50}
            color="icon"
            type="number"
            sx={{ bgcolor: "white", width: "40%" }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputProps: { min: 0, max: 100 },
            }}
          />
        </Box>
        {/* <Box display="flex" justifyContent="space-between" > */}
        <Button variant="contained" color="button" size="large" sx={{ m: 3 }}>
          Save
        </Button>
        {/* </Box> */}
      </Box>
    </>
  );
};

export default StaffCommuting;
