import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice"; // notifications : Alertslice.reducer,
import sdSlice from "./sdSlice";
// import { contactsApi } from "./billSlice";
export const store = configureStore({
  reducer: {
    auth: authSlice,
    site_details: sdSlice,
    // [contactsApi.reducerPath]: contactsApi.reducer,
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(contactsApi.middleware),
});
