import {
  Box,
  Divider,
  GlobalStyles,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import ChangePassword from "../components/Settings/ChangePassword";
import LogoUpdate from "../components/Settings/LogoUpdate";
import HelpIcon from "@mui/icons-material/Help";
import ChangeTheme from "../components/Settings/ChangeTheme";
import Logout from "../components/Settings/Logout";
import { DeviceLimit } from "../components/Login/DeviceLimit";
const Settings = () => {
  return (
    <>
      <GlobalStyles
        styles={(theme) => ({
          body: { backgroundColor: theme.palette.background.settings },
        })}
      />
      <Paper sx={{ my: 4, mx: 6, px: 4, mt: { xs: 10, sm: 3 } }} elevation={0}>
        <Box sx={{ display: "flex", alignItems: "center" }} pt={2}>
          <Typography fontWeight="bold">Change Company Logo</Typography>
          <Tooltip
            title={
              <>
                <Typography>
                  Here we add image with arrow to point logo in sidebar
                </Typography>
              </>
            }
          >
            <IconButton>
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Divider sx={{ mt: 2 }} />
        <LogoUpdate />
      </Paper>
      <Paper sx={{ my: 4, mx: 6, px: 4 }} elevation={0}>
        <Typography fontWeight="bold" pt={2}>
          Change Password
        </Typography>
        <Divider sx={{ my: 2 }} />
        <ChangePassword />
      </Paper>
      <Box display="flex" mx={6} gap={6}>
        <Paper
          sx={{
            px: 4,
            flexGrow: 2,
          }}
          elevation={0}
        >
          <Typography fontWeight="bold" pt={2}>
            Appearance
          </Typography>
          <Divider sx={{ my: 2 }} />
          <ChangeTheme />
        </Paper>
        <Paper
          sx={{
            px: 4,
            flexGrow: 1,
          }}
          elevation={0}
        >
          <Typography fontWeight="bold" pt={2}>
            Logout
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Logout />
        </Paper>
      </Box>
      {/* <Paper
        sx={{
          m: 2,
          px: 4,
        }}
        elevation={0}
      >
        <Typography pt={2}>Login Sessions</Typography>
        <DeviceLimit
          id="device-limit"
          keepMounted
          open={true}
          onClose={() => {}}
          value={[]}
          email={"ranjit@lnkit.co.uk"}
        />
      </Paper> */}
    </>
  );
};

export default Settings;
