import { Box, Typography } from "@mui/material";
import PlanCard from "./PlanCard";

const NoActivePlan = () => {
  return (
    <>
      <Box
        //  maxWidth=""
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        py={"11%"}
        // bgcolor={"red"}
      >
        <Typography variant="h4" color="initial" mb={1}>
          Choose your Pricing Plan
        </Typography>
        <Typography variant="h6" color="initial" mb={6}>
          You're no longer had active plan, Choose Plan from below to continue
        </Typography>
        <PlanCard
          textHeader={"You Don't have any Active Plan"}
          textCTA={"select your plan Starting from £ 38"}
        />
      </Box>
    </>
  );
};

export default NoActivePlan;
