import { Box, Typography } from "@mui/material";
import HelpBar from "./HelpBar";
import BTravelList from "./BTravelList";
import { waterList } from "./sub_cat_list";

const Water = () => {
  return (
    <>
      <HelpBar />
      <Box
        bgcolor={"background.default"}
        p={2}
        borderRadius={1}
        mt={4}
        minHeight={"100vh"}
      >
        <Box display={"flex"} flexDirection={"row-reverse"} mb={3}>
          <Typography variant="subtitle2" color="icon">
            Updated Just Now
          </Typography>
        </Box>
        <Typography mb={2}>
          List all your water consumption and processing activites here.
        </Typography>
        {/* <Typography mb={3}>
          This dosen't include employee commute or company owned or lealesd
          vehicle
        </Typography> */}

        <BTravelList
          category={"Water"}
          sub_category={"Proces Type"}
          sub_category_list={waterList ?? []}
          consumed_value={"Spend"}
          columnVisibility={false}
          unit={"M³"}
        />
      </Box>
    </>
  );
};

export default Water;
