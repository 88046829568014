import {
  Box,
  GlobalStyles,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { TimePicker } from "react-ios-time-picker";
import AddIcon from "@mui/icons-material/Add";
import SnackBarFunction from "../../../../utils/SnackBarFunction";

const TimeRange = ({ getData }) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [errors, setErros] = useState({
    loading: false,
    severity: "error",
    message: "Some Error Occured",
  });
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const timeInputPropStart = {
    placeHolder: isSmallScreen ? "Start Time" : "Select Off Time Start",
  };
  const timeInputPropEnd = {
    placeHolder: isSmallScreen ? "End Time" : "Select Off Time End",
  };
  return (
    <>
      <GlobalStyles
        styles={{
          ".TimePickerInput": {
            "@media (max-width: 992px)": {
              width: "6rem",
            },
          },
        }}
      />

      <Box
        sx={{
          display: "flex",
          // mt: 2,
          gap: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <Typography variant="subtitle2"></Typography> */}
        <TimePicker
          {...timeInputPropStart}
          onChange={(timeValue) => {
            setStartDate(timeValue);
          }}
          value={startDate}
          inputClassName="TimePickerInput"
        />

        <Typography variant="subtitle2">-</Typography>
        <TimePicker
          {...timeInputPropEnd}
          onChange={(timeValue) => {
            setEndDate(timeValue);
          }}
          value={endDate}
          inputClassName="TimePickerInput"
        />
        <IconButton
          color="ternary"
          sx={{ backgroundColor: "icon" }}
          onClick={() => {
            if (startDate !== undefined && endDate !== undefined) {
              getData({ startDate, endDate });
            } else {
              setErros({
                loading: true,
                severity: "error",
                message: "Date is Empty",
              });
              setTimeout(() => {
                setErros({
                  ...errors,
                  loading: false,
                });
              }, 1500);
            }
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>
      <SnackBarFunction
        isLoadingButton={errors.loading}
        severity={errors.severity}
        message={errors.message}
      />
    </>
  );
};

export default TimeRange;
