import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import NoActivePlan from "./NoActivePlan";
import UpgradePlan from "./UpgradePlan";
import { decryptData } from "../utils/js/encrypt-decrypt";
import { checkLogin, logout } from "../../features/authSlice";
const ProtectedRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userPricingPlan, isLoading, isError, isSuccess } = useSelector(
    (state) => state.auth
  );
  useEffect(() => {
    CheckLogin();
  }, []);

  const CheckLogin = () => {
    process.env.REACT_APP_BACKEND_URL ===
      "https://portal.carbonlnk.co.uk/api" &&
      axios
        .request({
          method: "get",
          url: "https://api.ipify.org?format=json",
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: false,
        })
        .then(async (response) => {
          const deviceIP = await response.data.ip;

          dispatch(checkLogin({ deviceIP })).then((onResolved) => {
            if (onResolved.payload === "error") {
              dispatch(
                logout({
                  ipofDeviceToRemove: deviceIP,
                })
              ).then((onResolved) => {
                if (onResolved.type === "auth/logout/fulfilled") {
                  localStorage.clear();
                  navigate("/login");
                }
              });
            } else if (onResolved.payload === "unauthorized") {
              localStorage.clear();
              navigate("/login");
            }
          });
        })
        .catch(() => {
          navigate("/login");
        });

    if (
      JSON.parse(decryptData(localStorage.getItem("ebgbvbfbjrbgjkkjrjbg"))) ===
      null
    ) {
      navigate("/login");
    }
  };

  if (userPricingPlan === 0) {
    return <NoActivePlan />;
  }
  if (
    userPricingPlan === 1 &&
    location.pathname === "/dashboard/ghg"
    // (location.pathname === "/billing" || location.pathname === "/dashboard/ghg")
  ) {
    return <UpgradePlan />;
  }
  return <Outlet />;
};

export default ProtectedRoutes;
