import { useEffect, useState } from "react";

const LastUpdatedTime = () => {
  const [minutes, setMinutes] = useState(0);
  const fetchTime = Date.now();

  const getTime = () => {
    const time = fetchTime - Date.now();

    setMinutes(Math.abs(Math.floor((time / 1000 / 60) % 60)) - 1);
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(fetchTime), 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="hidden md:flex md:items-center md:space-x-2">
      <span
        className="shrink-0 animate-pulse rounded-tremor-full bg-emerald-500/30 p-1"
        aria-hidden={true}
      >
        <span className="block h-2 w-2 rounded-tremor-full bg-emerald-500" />
      </span>
      <p className="mt-4 text-tremor-default text-tremor-content dark:text-dark-tremor-content md:mt-0">
        {minutes < 1 ? "Updated just now" : `Updated ${minutes} minutes ago`}
      </p>
    </div>
  );
};

export default LastUpdatedTime;
