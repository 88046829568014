import { useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  CircularProgress,
  Box,
} from "@mui/material";
import { Formik, Form } from "formik";

import validationSchema from "../../components/Devices/steeper/FormModel/validationSchema";
import checkoutFormModel from "../../components/Devices/steeper/FormModel/checkoutFormModel";
// import Review from "../../components/Devices/steeper/steps/Review";
import DeviceInfo from "../../components/Devices/steeper/steps/DeviceInfo";
import DeviceNickName from "../../components/Devices/steeper/steps/DeviceNickName";
// import LatLang from "../../components/Devices/steeper/steps/LatLang";
import TypeofElec from "../../components/Devices/steeper/steps/TypeOFElec";
import Threshold from "../../components/Devices/steeper/steps/Threshold";
import LekageChannel from "../../components/Devices/steeper/steps/LekageChannel";
// import Address from "../../components/Devices/steeper/steps/Address";
// import Department from "../../components/Devices/steeper/steps/Department";
import LocationSelect from "../../components/Devices/steeper/steps/LocationSelect";
import OffTime from "../../components/Devices/steeper/steps/Off-Time/OffTime";
import { useDispatch } from "react-redux";
import DeviceList from "./DeviceList";
import { deviceAdd, deviceUpdate } from "../../features/deviceSlice";
import SnackBarFunction from "../utils/SnackBarFunction";
import "../../styles/upload_media.css";

const DeviceUpload = ({ rowsData, onClose, dispatchType }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [errors, setErros] = useState({
    loading: false,
    severity: "error",
    message: "Some Error Occured",
  });
  const [offDates, setOffDates] = useState(rowsData?.offTime ?? []);
  const steps = [
    "Device Details (do not share with any one)",
    "Give Identifier name to Device",
    "Type of Electricty",
    "Device Location/Placement Details",
    // "Department Device Belongs to",
    "Select Off-Time",
    "Threshold Value",
    "Lekage Channel",
    // "REVIEW",
  ];
  const { formId, formField } = checkoutFormModel;
  const getDates = (prop) => {
    setOffDates(prop);
  };

  function _renderStepContent(step) {
    switch (step) {
      case 0:
        return <DeviceInfo formField={formField} />;
      case 1:
        return <DeviceNickName formField={formField} />;
      case 2:
        return <TypeofElec formField={formField} />;
      case 3:
        return <LocationSelect formField={formField} />;
      case 4:
        return (
          <OffTime getDates={getDates} offDates={offDates} viewOnly={false} />
        );
      case 5:
        return <Threshold formField={formField} />;
      case 6:
        return <LekageChannel formField={formField} />;
      // case 9:
      //   return <Review />;
      default:
        return <Box>Not Found</Box>;
    }
  }

  function createData({
    _id,
    deviceNickName,
    deviceGateway,
    deviceSerialNumber,
    deviceModel,
    deviceChannel,
    type,
    location,
    offTime,
    thresholdCost,
    lekageChannel,
  }) {
    return {
      _id,
      deviceNickName,
      deviceGateway,
      deviceSerialNumber,
      deviceModel,
      deviceChannel,
      type,
      location,
      offTime,
      thresholdCost,
      lekageChannel,
    };
  }
  const rows = data.map((e) => {
    return createData({
      _id: e._id,
      deviceNickName: e.deviceNickName,
      deviceGateway: e.deviceGateway,
      deviceSerialNumber: e.deviceSerialNumber,
      deviceModel: e.deviceModel,
      deviceChannel: e.deviceChannel,
      type: e.type,
      location: e.location,
      offTime: e.offTime,
      thresholdCost: e.thresholdCost,
      lekageChannel: e.lekageChannel,
    });
  });

  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  function _sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    await _sleep(1000);
    const isSS_Model = values.DeviceModel.substring(0, 2) === "ss";
    dispatchType === "add" || dispatchType === "clone"
      ? dispatch(
          deviceAdd({
            deviceNickName: values.DeviceNickName,
            deviceGateway: isSS_Model ? undefined : values.DeviceGateway,
            deviceSerialNumber: isSS_Model
              ? undefined
              : values.DeviceSerialNumber,
            deviceModel: values.DeviceModel,
            deviceChannel: isSS_Model ? undefined : values.DeviceChannel,
            type: values.elecTypeValue,
            location: values.Location,
            offTime: offDates,
            thresholdCost: values.ThresholdCost,
            lekageChannel: values.LekageChannel,
          })
        ).then((onResolved) => {
          if (onResolved.type === "device/add/fulfilled") {
            if (dispatchType === "clone") {
              onClose();
            }
            setData([...data, onResolved.payload.data]);
            setOffDates([]);
            setErros({
              loading: true,
              severity: "success",
              message: "Device Added",
            });
            setTimeout(() => {
              setErros({
                ...errors,
                loading: false,
              });
            }, 3000);
          } else {
            setErros({
              loading: true,
              severity: "error",
              message:
                onResolved.payload?.errors !== undefined
                  ? onResolved.payload?.errors[0].message
                  : "Some Error Occured,Tracking not started",
            });
            setTimeout(() => {
              setErros({
                ...errors,
                loading: false,
              });
            }, 3000);
          }
        })
      : dispatch(
          deviceUpdate({
            id: rowsData._id,
            deviceNickName: values.DeviceNickName,
            deviceGateway: values.DeviceGateway,
            deviceSerialNumber: values.DeviceSerialNumber,
            deviceModel: values.DeviceModel,
            deviceChannel: values.DeviceChannel,
            type: values.elecTypeValue,
            location: values.Location,
            offTime: offDates,
            thresholdCost: values.ThresholdCost,
            lekageChannel: values.LekageChannel,
          })
        ).then((onResolved) => {
          if (onResolved.type === "device/update/fulfilled") {
            onClose();
            setOffDates([]);
            setErros({
              loading: true,
              severity: "success",
              message: "Device Updated",
            });
            setTimeout(() => {
              setErros({
                ...errors,
                loading: false,
              });
            }, 3000);
          } else {
            setErros({
              loading: true,
              severity: "error",
              message:
                onResolved.payload?.errors !== undefined
                  ? onResolved.payload?.errors[0].message
                  : "Some Error Occured,Tracking not started",
            });
            setTimeout(() => {
              setErros({
                ...errors,
                loading: false,
              });
            }, 3000);
          }
        });
    actions.resetForm({
      values: {
        DeviceNickName: "",
        DeviceGateway: "",
        DeviceSerialNumber: "",
        DeviceModel: "",
        DeviceChannel: "",
        Location: "",
        offTime: "",
        ThresholdCost: "",
        LekageChannel: "",
      },
    });

    setTimeout(() => {
      actions.setSubmitting(false);
    }, 1000);

    setActiveStep(0);
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          boxShadow: "0 10px 60px rgb(159, 159, 160)",
          border: "1px solid rgb(159, 159, 160)",
          fontSize: "1.125rem",
          borderRadius: "1em",
          color: "rgb(15, 14, 14)",
          overflow: "visible",
          position: "relative",
          transition: "background-color 0.3s, color 0.3s",
          marginTop: "3rem",
        }}
      >
        <Box padding={"1.675em"}>
          {/* <Box
            component={"span"}
            sx={{
              fontSize: "1.25em",
              fontWeight: 600,
              lineHeight: 1.2,
              display: "flex",
              justifyContent: "center",
              color: "text.primary",
            }}
          ></Box> */}
          <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            <Box
              // component={"label"}
              sx={{
                backgroundColor: "background.primary",
                width: "100%",
                position: "relative",
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                marginTop: "2.1875rem",
                borderRadius: "10px",
                border: "2px dashed rgb(171, 202, 255)",
                color: " #444",
                transition:
                  "background 0.2s ease-in-out, border 0.2s ease-in-out",
                "&:hover": { backgroundColor: "background.paper" },
              }}
            >
              <Box
                component={"span"}
                sx={{
                  color: "text.primary",
                  fontSize: "20px",
                  fontWeight: "bold",
                  textAlign: "center",
                  transition: "color 0.2s ease-in-out",
                  marginBottom: "2rem",
                }}
              >
                Device Details
              </Box>

              <Box sx={{ width: "100%", marginBottom: 5 }}>
                <Stepper
                  activeStep={activeStep}
                  sx={{
                    my: 2,
                    "::-webkit-scrollbar": { display: "none" },
                    // "& .MuiStepConnector-line": {
                    //   borderColor: "red",
                    //   borderWidth: 3,
                    // },
                  }}
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <>
                  {activeStep === steps.length ? (
                    <></>
                  ) : (
                    <Formik
                      initialValues={{
                        DeviceNickName: rowsData?.deviceNickName ?? "",
                        DeviceGateway: rowsData?.deviceGateway ?? "",
                        DeviceSerialNumber: rowsData?.deviceSerialNumber ?? "",
                        DeviceModel: rowsData?.deviceModel ?? "",
                        DeviceChannel: rowsData?.deviceChannel ?? "",
                        elecTypeValue: rowsData?.type,
                        Location: rowsData?.location?._id,
                        offTime: rowsData?.offTime,
                        ThresholdCost: rowsData?.thresholdCost,
                        LekageChannel: rowsData?.lekageChannel,
                      }}
                      validationSchema={currentValidationSchema}
                      onSubmit={_handleSubmit}
                    >
                      {({ isSubmitting }) => (
                        <Form id={formId}>
                          {_renderStepContent(activeStep)}

                          <Box display="flex" gap={3} marginTop={1}>
                            {activeStep !== 0 && (
                              <Button
                                variant="text"
                                color="ternary"
                                onClick={_handleBack}
                              >
                                Back
                              </Button>
                            )}
                            <Button
                              disabled={isSubmitting}
                              type="submit"
                              variant="contained"
                              color="primary"
                              startIcon={
                                isSubmitting && (
                                  <CircularProgress size={24} color="ternary" />
                                )
                              }
                            >
                              {isLastStep
                                ? dispatchType === "add" ||
                                  dispatchType === "clone"
                                  ? "Add Record"
                                  : "Edit Record"
                                : "Next"}
                            </Button>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                  )}
                </>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {(dispatchType === "add" || dispatchType === "clone") && (
        <Box
          sx={{
            marginTop: "2rem",
            backgroundColor: "background.default",
            display: "flex",
            alignItems: "center",
            position: "relative",
            borderRadius: "1em",
          }}
        >
          <DeviceList rows={rows} inprows={rows} getId={() => {}} />
        </Box>
      )}

      <SnackBarFunction
        isLoadingButton={errors.loading}
        severity={errors.severity}
        message={errors.message}
      />
    </>
  );
};

export default DeviceUpload;
