import { useEffect, useState } from "react";
import { Grid, Stack, Switch, Typography, useMediaQuery } from "@mui/material";
import InputField from "../FormField/InputField";
import { useFormikContext } from "formik";

export default function DeviceInfo(props) {
  const { setFieldValue, values, touched, setTouched } = useFormikContext();
  const [checked, setChecked] = useState(
    values.DeviceModel.substring(0, 2) === "ss" ? false : true
  );
  const ismobile = useMediaQuery("(max-width:600px)");
  function handleClear() {
    setFieldValue("DeviceGateway", "");
    setFieldValue("DeviceSerialNumber", "");
    setFieldValue("DeviceChannel", "");
  }

  const handleSelect = (event) => {
    setChecked(event.target.checked);
    setFieldValue("DeviceModel", "ss-model-");
    handleClear();
  };

  useEffect(() => {
    if (values.DeviceModel.substring(0, 2) === "ss") {
      setChecked(false);
      handleClear();
    } else if (checked === false) {
      setChecked(true);
    }
    return () => {
      setTouched({ DeviceModel: false });
    };
  }, [touched.DeviceModel]);

  const {
    formField: {
      DeviceGateway,
      DeviceSerialNumber,
      DeviceModel,
      DeviceChannel,
    },
  } = props;
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Device Details
      </Typography>

      <Stack
        direction="row"
        spacing={1}
        display="flex"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        paddingBottom={2}
      >
        <Typography>SS model devices</Typography>
        <Switch checked={checked} onChange={handleSelect} color="ternary" />
        <Typography>AE model devices</Typography>
      </Stack>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <InputField
            disabled={!checked}
            name={DeviceGateway.name}
            label={DeviceGateway.label}
            fullWidth
            type="number"
            autoFocus
            // InputLabelProps={{ shrink: ismobile ? true : false }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            disabled={!checked}
            name={DeviceSerialNumber.name}
            label={DeviceSerialNumber.label}
            fullWidth
            type="number"
            // InputLabelProps={{ shrink: ismobile && true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={DeviceModel.name}
            label={DeviceModel.label}
            fullWidth
            // InputLabelProps={{ shrink: ismobile && true }}
          />
          {!checked && (
            <Typography variant="caption" display="block" gutterBottom>
              for SS-model-devices name should start with ss-
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            disabled={!checked}
            name={DeviceChannel.name}
            label={DeviceChannel.label}
            fullWidth
            type="number"
            // InputLabelProps={{ shrink: ismobile && true }}
          />
        </Grid>
      </Grid>
    </>
  );
}
