import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { notificationGet } from "../features/notifiicationSlice";
import { useDispatch } from "react-redux";
import moment from "moment";
import Loading from "../components/utils/Loading";

const Notification = () => {
  const dispatch = useDispatch();

  const [notificationData, setnotificationData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const groupByDate = notificationData.reduce((group, notificationData) => {
    const date = new Date(notificationData.timeCreated);
    const key = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    group[key] = group[key] || [];
    group[key].push(notificationData);
    return group;
  }, {});

  useEffect(() => {
    getNotoifications();
  }, []);

  const getNotoifications = () => {
    dispatch(notificationGet({})).then((onResolved) => {
      if (onResolved.type === "noti/get/fulfilled") {
        setIsLoading(false);
        setnotificationData(onResolved.payload.notification);
      }
    });
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  };

  return (
    <Box sx={{ maxWidth: "100vw", mt: { xs: 10, sm: 3 } }}>
      {isLoading ? (
        <>
          <Box mx={10} my={"25%"}>
            <Loading />
          </Box>
        </>
      ) : notificationData.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "25%",
          }}
        >
          {/* <img
            alt="notification bell"
            src="/images/bell.png"
            width="300"
            height="300"
          ></img> */}
          <Typography variant="h1" color="text.primary">
            No Notifcation
          </Typography>
          <Typography
            variant="h6"
            color="text.primary"
            component="h2"
            align="center"
            style={{ wordWrap: "break-word" }}
          >
            Check This Section For Thresold Limit Cross By Device, your
            activities and General Notifcation.
          </Typography>
        </Box>
      ) : (
        Object.entries(groupByDate).map((entry, category_index) => {
          let category_key = entry[0];
          let category_value = entry[1];
          const groupByDepartment = category_value.reduce(
            (group, category_value) => {
              const { department } = category_value.payload;
              group[department] = group[department] ?? [];
              group[department].push(category_value);
              return group;
            },
            {}
          );
          return (
            <Box sx={{ mx: 6, mt: 2 }}>
              <Accordion
                sx={{
                  bgcolor: "background.default",
                  border: "1px solid rgb(159, 159, 160,0.2)",
                  borderRadius: "1em",
                }}
                defaultExpanded={true}
                key={category_index}
                elevation={0}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h5" color="text.primary">
                    {moment.utc(category_key).format("dddd, Do MMM YYYY")}
                  </Typography>
                </AccordionSummary>
                {Object.entries(groupByDepartment).map((deptEntry, index) => {
                  let dept_key =
                    deptEntry[0] === "undefined"
                      ? "Activities" //deptEntry[1][0]?.type
                      : deptEntry[0];
                  let dept_value = deptEntry[1];
                  return (
                    <>
                      <Accordion
                        // sx={{ bgcolor: "background.default", mx: 2, mb: 1 }}
                        sx={{
                          mx: 2,
                          mb: 1,
                          bgcolor: "background.paper",
                        }}
                        key={index}
                        elevation={0}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography variant="h5" color="text.primary">
                            {dept_key}
                          </Typography>
                        </AccordionSummary>

                        {dept_value.map((e, eindex) => (
                          <AccordionDetails key={eindex}>
                            <Alert
                              severity={
                                e.type === "thresold" ? "error" : "info"
                              }
                            >
                              <AlertTitle>
                                {e.type === "thresold" ? (
                                  "Your Consumed More energy"
                                ) : (
                                  <>
                                    <ListItemText
                                      primaryTypographyProps={{
                                        style: { color: "text.secondary" },
                                      }}
                                      primary={`Info: ${e?.type}`}
                                    />
                                  </>
                                )}
                              </AlertTitle>
                              {Object.entries(e?.payload).map(
                                ([key, value], index) => (
                                  <ListItem disablePadding key={index}>
                                    {e?.payload[key] !== "" && (
                                      <>
                                        <ListItemText
                                          primary={`${key.toUpperCase()}:`}
                                        />
                                        <ListItemText
                                          primary={
                                            key === "time"
                                              ? moment(value).format("LT")
                                              : value
                                          }
                                          sx={{
                                            alignItems: "left",
                                            alignContent: "left",
                                            justifyContent: "left",
                                            marginLeft: 15,
                                          }}
                                        />
                                      </>
                                    )}
                                  </ListItem>
                                )
                              )}
                            </Alert>
                          </AccordionDetails>
                        ))}
                      </Accordion>
                    </>
                  );
                })}
              </Accordion>
            </Box>
          );
        })
      )}
    </Box>
  );
};

export default Notification;
