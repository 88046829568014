import { Box, IconButton, SvgIcon, Typography } from "@mui/material";
import HelpBar from "./HelpBar";
import BTravelList from "./BTravelList";
import { useState } from "react";
import { ReactComponent as ArrowDownIcon } from "../../../styles/icons/arrow_downIcon.svg";
import { travelList } from "./sub_cat_list";

const Transport = () => {
  const [incoming, setIncoming] = useState(true);
  const [outgoing, setOutgoing] = useState(true);

  return (
    <>
      <HelpBar />
      <Box
        bgcolor={"background.default"}
        p={2}
        borderRadius={1}
        mt={4}
        minHeight={"100vh"}
      >
        <Box display={"flex"} flexDirection={"row-reverse"} mb={3}>
          <Typography variant="subtitle2" color="icon">
            Updated Just Now
          </Typography>
        </Box>
        <Typography mb={2}>
          Do you use third party supplier for Frieght/Transport
        </Typography>
        {/* <Typography mb={3}>
          This dosen't include employee commute or company owned or lealesd
          vehicle
        </Typography> */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2" color="initial">
            Incoming
          </Typography>
          <IconButton
            aria-label="dropdown"
            size="small"
            onClick={() => setIncoming(!incoming)}
          >
            <SvgIcon component={ArrowDownIcon} />
          </IconButton>
        </Box>

        {incoming && (
          <BTravelList
            category={"Transport Incoming"}
            sub_category={"Vehicle Type"}
            sub_category_list={travelList ?? []}
            consumed_value={"Distance"}
            sub_category_identifier={"Weight"}
            unit={"KM"}
            fakeData={[]}
          />
        )}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2" color="initial">
            Outgoing
          </Typography>
          <IconButton
            aria-label="dropdown"
            size="small"
            onClick={() => setOutgoing(!outgoing)}
          >
            <SvgIcon component={ArrowDownIcon} />
          </IconButton>
        </Box>
        {outgoing && (
          <BTravelList
            category={"Transport Outgoing"}
            sub_category={"Vehicle Type"}
            consumed_value={"Distance"}
            sub_category_identifier={"Weight"}
          />
        )}
      </Box>
    </>
  );
};

export default Transport;
