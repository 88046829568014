import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import "../../styles/verifyotp.css";
import { useTheme } from "@mui/material/styles";
import { resendOtp, updatePassword, verifyOtp } from "../../features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CountUp from "react-countup";
import * as yup from "yup";
import { MuiOtpInput } from "mui-one-time-password-input";

const validationSchema = yup.object({
  NewPass: yup
    .string("Enter your old password")
    .required("Password is required")
    .min(8, "Password should be of minimum 8 characters length")
    .matches(/^((?!CLGENAUTO).)*$/i, "Should not Start with")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .notOneOf([yup.ref("OldPass")], "New Password Shouldn't be same"),
  ConfirmPass: yup
    .string("Enter your password")
    .required("Enter New Password Again")
    .min(8, "Password should be of minimum 8 characters length")
    .oneOf([yup.ref("NewPass")], "Passwords does not match"),
});

const FirstPassword = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { user, isLoading, isError, isSuccess, message } = useSelector(
  //   (state) => state.auth
  // );
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [isOtpResend, setIsOtpResend] = useState(false);

  const [otp, setOtp] = useState("");

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const otpformik = useFormik({
    initialValues: {
      NewPass: "",
      ConfirmPass: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      setIsLoadingButton(true);
      //   alert(JSON.stringify(values, null, 2));

      axios
        .request({
          method: "get",
          url: "https://api.ipify.org?format=json",
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: false,
        })
        .then(async (response) => {
          const deviceIP = await response.data.ip;
          dispatch(
            verifyOtp({
              email: props.email?.toLowerCase(),
              otp,
              deviceIP,
              forCP: true,
              newPassword: values.NewPass,
            })
          ).then((onResolved) => {
            if (onResolved.type === "auth/verify/fulfilled") {
              props.forCP ? props.onClose() : navigate("/");
            } else {
              setLoginFailed(true);
            }
          });
        })
        .catch(() => {
          setLoginFailed(true);
        });

      setTimeout(() => {
        actions.setSubmitting(false);
        setIsLoadingButton(false);
      }, 1000);
    },
    onReset: () => {
      props.onClose();
    },
  });

  const handleResend = () => {
    setIsOtpResend(true);
    dispatch(resendOtp({ email: props.email?.toLowerCase() }));
    setTimeout(() => {
      setIsOtpResend(false);
    }, 900);
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        aria-labelledby="responsive-dialog-title"
        maxWidth={"xs"}
      >
        <DialogTitle
          id="responsive-dialog-title"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {"Verify OTP send on your email,"}
          {<br />} Enter new Secure Password
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* <p className="otpheading">Verify</p> */}
            <svg
              className="otpcheck"
              version="1.1"
              id="Layer_1"
              x="0px"
              y="0px"
              width="100%"
              height="60px"
              viewBox="0 0 60 60"
            >
              <image
                id="image0"
                width="60"
                height="60"
                x="0"
                y="0"
                href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAQAAACQ9RH5AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
  AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZ
  cwAACxMAAAsTAQCanBgAAAAHdElNRQfnAg0NDzN/r+StAAACR0lEQVRYw+3Yy2sTURTH8W+bNgVf
  aGhFaxNiAoJou3FVEUQE1yL031BEROjCnf4PLlxILZSGYncuiiC48AEKxghaNGiliAojiBWZNnNd
  xDza3pl77jyCyPzO8ubcT85wmUkG0qT539In+MwgoxQoUqDAKDn2kSNLlp3AGi4uDt9xWOUTK3xg
  hVU2wsIZSkxwnHHGKZOxHKfBe6rUqFGlTkPaVmKGn6iYao1ZyhK2zJfY0FZ9ldBzsbMKxZwZjn/e
  5szGw6UsD5I0W6T+hBhjUjiF7bNInjz37Ruj3igGABjbtpIo3GIh30u4ww5wr3fwfJvNcFeznhBs
  YgXw70TYX2bY/ulkZhWfzfBbTdtrzjPFsvFI+T/L35jhp5q2owDs51VIVvHYDM9sa/LY8XdtKy1l
  FXfM8FVN2/X2ajctZxVXzPA5TZvHpfb6CFXxkerUWTOcY11LX9w0tc20inX2mmF4qG3upnNWrOKB
  hIXLPu3dF1x+kRWq6ysHpkjDl+7eQjatYoOCDIZF3006U0unVSxIWTgTsI3HNP3soSJkFaflMDwL
  3OoHrph9YsPCJJ5466DyOGUHY3Epg2rWloUxnMjsNw7aw3AhMjwVhgW4HYm9FZaFQZ/bp6QeMRQe
  hhHehWKXGY7CAuSpW7MfKUZlAUqWdJ3DcbAAB3guZl9yKC4WYLfmT4muFtgVJwvQx7T2t0mnXK6J
  XlGGyAQvfNkaJ5JBmxnipubJ5HKDbJJsM0eY38QucSx5tJWTVHBwqDDZOzRNmn87fwDoyM4J2hRz
  NgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMy0wMi0xM1QxMzoxNTo1MCswMDowMKC8JaoAAAAldEVY
  dGRhdGU6bW9kaWZ5ADIwMjMtMDItMTNUMTM6MTU6NTArMDA6MDDR4Z0WAAAAKHRFWHRkYXRlOnRp
  bWVzdGFtcAAyMDIzLTAyLTEzVDEzOjE1OjUxKzAwOjAwIIO3fQAAAABJRU5ErkJggg=="
              ></image>
            </svg>
            <form onSubmit={otpformik.handleSubmit} className="otpform">
              {loginFailed && (
                <Alert severity="error">Password Changed Failed Check Your Credentials</Alert>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mx: 2,
                  mt: 3,
                  gap: 2,
                  maxWidth: "90%",
                }}
              >
                <TextField
                  id="NewPass"
                  name="NewPass"
                  placeholder="Enter New Password"
                  type="password"
                  value={otpformik.values.NewPass}
                  onChange={otpformik.handleChange}
                  error={
                    otpformik.touched.NewPass &&
                    Boolean(otpformik.errors.NewPass)
                  }
                  helperText={
                    otpformik.touched.NewPass && otpformik.errors.NewPass
                  }
                />
                <TextField
                  id="ConfirmPass"
                  name="ConfirmPass"
                  placeholder="Confirm New Password"
                  type="password"
                  value={otpformik.values.ConfirmPass}
                  onChange={otpformik.handleChange}
                  error={
                    otpformik.touched.ConfirmPass &&
                    Boolean(otpformik.errors.ConfirmPass)
                  }
                  helperText={
                    otpformik.touched.ConfirmPass &&
                    otpformik.errors.ConfirmPass
                  }
                />
                <Typography variant="subtitle2" marginLeft={1}>
                  Enter OTP:
                </Typography>
              </Box>
              <MuiOtpInput
                value={otp}
                onChange={handleChange}
                sx={{ mt: 5, mx: { xs: 2, md: 6 } }}
              />
              <button
                style={{ backgroundColor: "#42a5f5" }}
                type="submit"
                className="otpbtn1"
              >
                {isLoadingButton ? (
                  <CircularProgress color="button" />
                ) : (
                  "Submit"
                )}
              </button>
              <Typography
                marginRight={2}
                marginTop={1}
                textAlign={"right"}
                variant="caption"
                sx={{ cursor: isOtpResend ? "not-allowed" : "pointer" }}
                onClick={() => handleResend()}
              >
                {isOtpResend ? (
                  <>
                    <CountUp
                      start={0}
                      end={30}
                      duration={30}
                      useEasing={true}
                      separator=","
                      prefix="Seconds: "
                    />
                  </>
                ) : (
                  " Resend OTP"
                )}
              </Typography>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="ternary" onClick={otpformik.handleReset} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FirstPassword;
