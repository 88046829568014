import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const SidebarCmpLogo = ({ open }) => {
  const { userLogo } = useSelector((state) => state.auth);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: open ? "background.default" : "inherit",
          margin: 1.5,
          paddingY: 4,
          borderRadius: 3,
        }}
      >
        <Box
          sx={{
            backgroundColor: "#fde196",
            borderRadius: "50%",
            p: 1,
            width: "5rem",
            height: "5rem",
            overflow: "clip",
          }}
        >
          {userLogo ? (
            <img
              src={userLogo}
              alt="logo"
              width="100%"
              height="100%"
              style={{ borderRadius: "50%" }}
            />
          ) : (
            <Typography sx={{ p: 2 }}>Logo</Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SidebarCmpLogo;
