import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SvgIcon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import "../../styles/verifyotp.css";
import { useTheme } from "@mui/material/styles";
import { resendOtp, verifyOtp } from "../../features/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CountUp from "react-countup";
import { ReactComponent as OtpShieldIcon } from "../../styles/icons/otpShield.svg";
import { MuiOtpInput } from "mui-one-time-password-input";
import { getSites } from "../../features/sdSlice";

const VerifyEmailOtp = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { user, isLoading, isError, isSuccess, message } = useSelector(
  //   (state) => state.auth
  // );

  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [isOtpResend, setIsOtpResend] = useState(false);
  const [otp, setOtp] = useState("");

  const handleChange = (newValue) => {
    setOtp(newValue);
  };
  const otpSubmit = (event) => {
    event.preventDefault();
    setIsLoadingButton(true);

    axios
      .request({
        method: "get",
        url: "https://api.ipify.org?format=json",
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: false,
      })
      .then(async (response) => {
        const deviceIP = await response.data.ip;
        dispatch(
          verifyOtp({
            email: props.email?.toLowerCase(),
            otp,
            deviceIP,
            forCP: props.forCP,
            newPassword: props.newPassword,
          })
        ).then((onResolved) => {
          if (onResolved.type === "auth/verify/fulfilled") {
            dispatch(getSites());
            props.forCP ? props.onClose() : navigate("/", { replace: true });
          } else {
            setLoginFailed(true);
          }
        });
      })
      .catch(() => {
        setLoginFailed(true);
      });

    setTimeout(() => {
      setIsLoadingButton(false);
    }, 1000);
  };

  const handleResend = () => {
    setIsOtpResend(true);
    dispatch(resendOtp({ email: props.email?.toLowerCase() }));
    setTimeout(() => {
      setIsOtpResend(false);
    }, 900);
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Verify OTP send on your email"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* <p className="otpheading">Verify</p> */}
            <div className="flex items-center justify-center">
              <SvgIcon fontSize="large" component={OtpShieldIcon} />
            </div>
            <form onSubmit={otpSubmit} className="otpform">
              {loginFailed && (
                <Alert severity="error">Login Failed Check Credentials</Alert>
              )}
              <MuiOtpInput
                autoFocus
                value={otp}
                onChange={handleChange}
                sx={{ mt: 5, mx: { xs: 2, md: 6 } }}
              />
              <Typography
                marginRight={2}
                marginTop={1}
                textAlign={"right"}
                variant="caption"
                sx={{ cursor: isOtpResend ? "not-allowed" : "pointer" }}
                onClick={() => handleResend()}
              >
                {isOtpResend ? (
                  <>
                    <CountUp
                      start={0}
                      end={30}
                      duration={30}
                      useEasing={true}
                      separator=","
                      prefix="Seconds: "
                    />
                  </>
                ) : (
                  " Resend OTP"
                )}
              </Typography>

              <button type="submit" className="otpbtn1">
                {isLoadingButton ? (
                  <CircularProgress size={20} color="button" />
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="ternary"
            onClick={() => {
              setOtp("");
              props.onClose();
            }}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VerifyEmailOtp;
