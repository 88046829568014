import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import PlanCard from "./PlanCard";

const UpgradePlan = () => {
  return (
    <>
      <Alert severity="error" sx={{ mx: 5, mt: 2 }}>
        <AlertTitle>Access Denied</AlertTitle>
        You don't have acess to this module. Please upgrade your current plan to
        access module.
      </Alert>
      <Box
        //  maxWidth=""
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        py={"5%"}
        // bgcolor={"red"}
      >
        <Typography variant="h4" color="initial" mb={1}>
          Upgrade your Current Plan
        </Typography>
        <Typography variant="h6" color="initial" mb={6}>
          You're current active plan, doesn't have access to this functinality
        </Typography>
        <PlanCard
          textHeader={"Upgrade Current Plan to Access"}
          textCTA={"upgrade your plan"}
        />
      </Box>
    </>
  );
};

export default UpgradePlan;
