import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  decryptData,
  encryptData,
} from "../components/utils/js/encrypt-decrypt";
const sitesDevices = localStorage.getItem("sdklsdifghFOz8956jkhjk");
const initialState = {
  value: [],
  sitesDevices: sitesDevices ? JSON.parse(decryptData(sitesDevices)) : [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  index: {
    countryIndex: null,
    facilityIndex: null,
    sectionIndex: null,
    floorIndex: null,
    departmentIndex: null,
  },
};

const sdSlice = createSlice({
  name: "site_details",
  initialState,
  reducers: {
    changeIndex: (state, { payload }) => {
      state.index = { ...state.index, ...payload };
    },
    // reset: (state) => {
    //   state.isLoading = false;
    //   state.isSuccess = false;
    //   state.isError = false;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSites.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSites.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.value = action.payload.result;
        state.sitesDevices = action.payload.sitesDevices;
      })
      .addCase(getSites.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        // state.value = [];
      })
      .addCase(addCountry.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addCountry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.value.push(action.payload);
        // state.value = state.value.push(action.payload);
      })
      .addCase(addCountry.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        // state.value = [];
      })
      .addCase(addFacility.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addFacility.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.value[state.index?.countryIndex].facility.push(action.payload);
      })
      .addCase(addFacility.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        // state.value = [];
      })
      .addCase(addSection.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addSection.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.value[state.index?.countryIndex].facility[
          state.index?.facilityIndex
        ].section.push(action.payload);
      })
      .addCase(addSection.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        // state.value = [];
      })
      .addCase(addFloor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addFloor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.value[state.index?.countryIndex].facility[
          state.index?.facilityIndex
        ].section[state.index?.sectionIndex].floor.push(action.payload);
      })
      .addCase(addFloor.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        // state.value = [];
      })
      .addCase(addDepartment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addDepartment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.value[state.index?.countryIndex].facility[
          state.index?.facilityIndex
        ].section[state.index?.sectionIndex].floor[
          state.index?.floorIndex
        ].department.push(action.payload);
      })
      .addCase(addDepartment.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        // state.value = [];
      })
      .addCase(sdDelete.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        switch (action.payload.method) {
          case "country":
            {
              state.value.splice(action.payload.removeIndex, 1);
            }
            break;
          case "facility":
            {
              const data = state?.value[state?.index?.countryIndex]?.facility;

              data?.splice(action.payload.removeIndex, 1);
            }
            break;
          case "section":
            {
              const data =
                state?.value[state?.index?.countryIndex]?.facility?.[
                  state?.index?.facilityIndex
                ]?.section;

              data?.splice(action.payload.removeIndex, 1);
            }
            break;
          case "floor":
            {
              const data =
                state?.value[state?.index?.countryIndex]?.facility?.[
                  state?.index?.facilityIndex
                ]?.section?.[state?.index?.sectionIndex]?.floor;

              data?.splice(action.payload.removeIndex, 1);
            }
            break;
          case "department":
            {
              const data =
                state?.value[state?.index?.countryIndex]?.facility?.[
                  state?.index?.facilityIndex
                ]?.section?.[state?.index?.sectionIndex]?.floor[
                  state?.index.floorIndex
                ]?.department;

              data?.splice(action.payload.removeIndex, 1);
            }
            break;
        }
      });
  },
});

export const addCountry = createAsyncThunk(
  "sd/country",
  async (values, { rejectWithValue }) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/site_details`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            country: values.country,
            facility: values.facility,
            section: values.section,
            floor: values.floor,
            department: values.department,
          }),
        }
      );
      // const data = await res.json();

      if (res.status === 200) {
        const processedData = {
          _id: Math.floor(Math.random() * 100),
          key: values.country,
          facility: [
            {
              _id: Math.floor(Math.random() * 100),
              key: values.facility,
              section: [
                {
                  _id: Math.floor(Math.random() * 100),
                  key: values.section,
                  floor: [
                    {
                      _id: Math.floor(Math.random() * 100),
                      key: values.floor,
                      department: [
                        {
                          _id: Math.floor(Math.random() * 100),
                          key: values.department,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        };
        return processedData;
      } else {
        return rejectWithValue("error");
      }
    } catch (error) {
      return rejectWithValue("error");
    }
  }
);

export const addFacility = createAsyncThunk(
  "sd/facility",
  async (values, { rejectWithValue }) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/site_details`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            country: values.country,
            facility: values.facility,
            section: values.section,
            floor: values.floor,
            department: values.department,
          }),
        }
      );
      // const data = await res.json();

      if (res.status === 200) {
        const processedData = {
          _id: Math.floor(Math.random() * 100),
          key: values.facility,
          section: [
            {
              _id: Math.floor(Math.random() * 100),
              key: values.section,
              floor: [
                {
                  _id: Math.floor(Math.random() * 100),
                  key: values.floor,
                  department: [
                    {
                      _id: Math.floor(Math.random() * 100),
                      key: values.department,
                    },
                  ],
                },
              ],
            },
          ],
        };
        return processedData;
      } else {
        return rejectWithValue("error");
      }
    } catch (error) {
      return rejectWithValue("error");
    }
  }
);

export const addSection = createAsyncThunk(
  "sd/section",
  async (values, { rejectWithValue }) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/site_details`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            country: values.country,
            facility: values.facility,
            section: values.section,
            floor: values.floor,
            department: values.department,
          }),
        }
      );
      // const data = await res.json();

      if (res.status === 200) {
        const processedData = {
          _id: Math.floor(Math.random() * 100),
          key: values.section,
          floor: [
            {
              _id: Math.floor(Math.random() * 100),
              key: values.floor,
              department: [
                {
                  _id: Math.floor(Math.random() * 100),
                  key: values.department,
                },
              ],
            },
          ],
        };
        return processedData;
      } else {
        return rejectWithValue("error");
      }
    } catch (error) {
      return rejectWithValue("error");
    }
  }
);

export const addFloor = createAsyncThunk(
  "sd/floor",
  async (values, { rejectWithValue }) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/site_details`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            country: values.country,
            facility: values.facility,
            section: values.section,
            floor: values.floor,
            department: values.department,
          }),
        }
      );
      // const data = await res.json();

      if (res.status === 200) {
        const processedData = {
          _id: Math.floor(Math.random() * 100),
          key: values.floor,
          department: [
            {
              _id: Math.floor(Math.random() * 100),
              key: values.department,
            },
          ],
        };
        return processedData;
      } else {
        return rejectWithValue("error");
      }
    } catch (error) {
      return rejectWithValue("error");
    }
  }
);

export const addDepartment = createAsyncThunk(
  "sd/department",
  async (values, { rejectWithValue }) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/site_details`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            country: values.country,
            facility: values.facility,
            section: values.section,
            floor: values.floor,
            department: values.department,
            lat: values.lat,
            lng: values.lng,
          }),
        }
      );

      if (res.status === 200) {
        const processedData = {
          _id: Math.floor(Math.random() * 100),
          key: values.department,
          cordinates: [
            {
              _id: Math.floor(Math.random() * 100),
              lat: values.lat,
              lng: values.lng,
            },
          ],
        };
        return processedData;
      } else {
        return rejectWithValue("error");
      }
    } catch (error) {
      return rejectWithValue("error");
    }
  }
);

export const getSites = createAsyncThunk(
  "sd/get",
  async (values, { rejectWithValue }) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/site_details`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      const data = await res.json();

      if (res.status === 200) {
        localStorage.setItem(
          "sdklsdifghFOz8956jkhjk",
          encryptData(JSON.stringify(data?.sitesDevices))
        );
        return { result: data?.result, sitesDevices: data?.sitesDevices };
      } else {
        return rejectWithValue("error");
      }
    } catch (error) {
      return rejectWithValue("error");
    }
  }
);

export const sdDelete = createAsyncThunk(
  "devices/del",
  async ({ method }, { getState, rejectWithValue }) => {
    const state = getState();
    const dataValue = state["site_details"].value;
    const dataIndex = state["site_details"].index;
    switch (method) {
      case "country": {
        const id = dataValue[dataIndex.countryIndex]._id;
        const removeIndex = dataValue.map((e) => e._id).indexOf(id);
        await deleteFunc(id);
        return { removeIndex, method };
      }

      case "facility": {
        const data = dataValue[dataIndex?.countryIndex]?.facility;
        const id = data[dataIndex?.facilityIndex]._id;
        const removeIndex = data?.map((e) => e._id).indexOf(id);
        await deleteFunc(id);
        return { removeIndex, method };
      }

      case "section": {
        const data =
          dataValue[dataIndex?.countryIndex]?.facility?.[
            dataIndex?.facilityIndex
          ]?.section;
        const id = data[dataIndex?.sectionIndex]._id;
        const removeIndex = data?.map((e) => e._id).indexOf(id);
        await deleteFunc(id);
        return { removeIndex, method };
      }

      case "floor": {
        const data =
          dataValue[dataIndex?.countryIndex]?.facility?.[
            dataIndex?.facilityIndex
          ]?.section?.[dataIndex?.sectionIndex]?.floor;
        const id = data[dataIndex?.floorIndex]._id;
        const removeIndex = data?.map((e) => e._id).indexOf(id);
        await deleteFunc(id);
        return { removeIndex, method };
      }

      case "department": {
        const data =
          dataValue[dataIndex?.countryIndex]?.facility?.[
            dataIndex?.facilityIndex
          ]?.section?.[dataIndex?.sectionIndex]?.floor[dataIndex.floorIndex]
            ?.department;
        const id = data[dataIndex?.departmentIndex]._id;
        const removeIndex = data?.map((e) => e._id).indexOf(id);
        await deleteFunc(id);
        return { removeIndex, method };
      }
    }
    async function deleteFunc(id) {
      console.log("errr");
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/site_details`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
              id,
            }),
          }
        );
        const data = await res.json();

        if (res.status === 200) {
          id = "";
          return data;
        } else {
          id = "";
          return rejectWithValue("error");
        }
      } catch (error) {
        id = "";
        return rejectWithValue("error");
      }
    }
  }
);

export const { changeIndex, decrement } = sdSlice.actions;

export default sdSlice.reducer;
