import styles from "../../styles/planCard.module.css";

const PlanCard = ({ textHeader, textCTA }) => {
  return (
    <div className="bg-[#ffeaea] px-10 py-8 rounded-md max-h-0">
      <a href="https://www.carbonlnk.co.uk/pricing-plans/list" target="_blank">
        <div className={styles.card}>
          <div className={styles.card_name}>{textHeader}</div>
          <div className={styles.quote}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 290.626 290.626"
              height="80"
              width="80"
            >
              <circle fill="currentColor" cx="133.595" cy="77.344" r="44.531" />
              <path
                fill="currentColor"
                d="M276.563 0h-262.5C6.311 0 .001 6.309.001 14.063V150c0 7.753 6.309 14.063 14.062 14.063h50.184c-.609.488-1.252.933-1.814 1.491-3.989 3.984-6.183 9.281-6.183 14.916s2.194 10.931 6.178 14.916l50.072 50.067v45.173h9.375V241.57l-52.819-52.814c-2.212-2.217-3.431-5.156-3.431-8.288s1.219-6.07 3.431-8.288c4.43-4.43 12.141-4.43 16.57 0l36.248 36.244V82.031c0-6.464 5.255-11.719 11.719-11.719s11.719 5.255 11.719 11.719V150h9.375v-11.719c0-6.464 5.255-11.719 11.719-11.719s11.719 5.255 11.719 11.719V150h9.375v-7.031c0-6.464 5.255-11.719 11.719-11.719s11.719 5.255 11.719 11.719v11.719h9.375v-2.344c0-6.464 5.255-11.719 11.719-11.719s11.719 5.255 11.719 11.719v100.781c0 7.753-6.309 14.063-14.062 14.063h-4.688v23.438h9.375V276.09c10.683-2.18 18.75-11.644 18.75-22.964v-89.063h23.437c7.753 0 14.062-6.309 14.062-14.063V14.063C290.626 6.309 284.316 0 276.563 0M9.376 150V14.063a4.69 4.69 0 0 1 4.687-4.688h28.125v145.312H14.064A4.69 4.69 0 0 1 9.376 150m82.884 15.553c-.563-.563-1.205-1.003-1.814-1.491h22.055v21.731zm139.772-34.303c-4.941 0-9.431 1.777-13.031 4.636-2.925-8.142-10.645-14.011-19.781-14.011-5.677 0-10.814 2.278-14.611 5.939-3.647-6.319-10.397-10.627-18.202-10.627a21 21 0 0 0-11.719 3.572V82.031c0-11.63-9.464-21.094-21.094-21.094s-21.093 9.465-21.093 21.094v72.656H51.564V9.375h192.187v125.447a20.97 20.97 0 0 0-11.719-3.572M281.251 150a4.69 4.69 0 0 1-4.687 4.688h-23.437V9.375h23.437a4.69 4.69 0 0 1 4.687 4.688z"
              />
              <path
                fill="currentColor"
                d="M262.501 93.75h9.375v9.375h-9.375zm0-32.812h9.375v28.125h-9.375zm-236.719 37.5c9.047 0 16.406-7.359 16.406-16.406s-7.359-16.406-16.406-16.406S9.376 72.984 9.376 82.031s7.36 16.407 16.406 16.407m0-23.438c3.877 0 7.031 3.155 7.031 7.031s-3.155 7.031-7.031 7.031-7.031-3.155-7.031-7.031S21.906 75 25.782 75"
              />
            </svg>
          </div>
          <div className={styles.author}>
            - {textCTA}
            <svg
              width="18"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M16.5 3C19.538 3 22 5.5 22 9c0 7-7.5 11-10 12.5C9.5 20 2 16 2 9c0-3.5 2.5-6 5.5-6C9.36 3 11 4 12 5c1-1 2.64-2 4.5-2" />
            </svg>
          </div>
        </div>
      </a>
    </div>
  );
};

export default PlanCard;
