import "../App.css";
import { Box, Fade, Typography, keyframes, useMediaQuery } from "@mui/material";
import { decryptData } from "../components/utils/js/encrypt-decrypt";
import Loading from "../components/utils/Loading";
import { useState } from "react";

const HomeV2 = () => {
  const ismobile = useMediaQuery("(max-width:600px)");
  const [isLoading, setIsLoading] = useState(true);

  let url = JSON.parse(decryptData(localStorage.getItem("ddsmhfywief87we8f8")));
  const isDark = localStorage.getItem("choosenTheme") === "dark";
  const dashboard_theme = isDark ? "dark_theme" : "light_theme";
  
  const blobBounce = keyframes`
  0% {
    transform: translate(-100%, -100%) translate3d(0, 0, 0);
  }

  25% {
    transform: translate(-100%, -100%) translate3d(100%, 0, 0);
  }

  50% {
    transform: translate(-100%, -100%) translate3d(100%, 100%, 0);
  }

  75% {
    transform: translate(-100%, -100%) translate3d(0, 100%, 0);
  }

  100% {
    transform: translate(-100%, -100%) translate3d(0, 0, 0);
  }
`;
  return (
    <>
      {ismobile ? (
        <Box
          sx={{
            top: "50%",
            left: "25%",
            position: "relative",
            width: "200px",
            height: "250px",
            borderRadius: "14px",
            zIndex: 1111,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "20px 20px 60px #bebebe, -20px -20px 60px #ffffff",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "5px",
              left: "5px",
              width: "190px",
              height: "240px",
              zIndex: 2,
              background: "#fffffff2",
              backdropFilter: "blur(24px)",
              borderRadius: "10px",
              overflow: "hidden",
              outline: "2px solid white",
            }}
          >
            <Typography m={1} color="black">
              Dashboard Not Available in Mobile please go to Desktop or Table.
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              zIndex: 1,
              top: "50%",
              left: "50%",
              width: "150px",
              height: "150px",
              borderRadius: "50%",
              backgroundColor: "#ff0000",
              opacity: 1,
              filter: "blur(12px)",
              animation: `${blobBounce} 5s infinite ease`,
            }}
          ></Box>
        </Box>
      ) : (
        <>
          {isLoading && (
            <Box mx={10} my={"15%"}>
              <Loading />
            </Box>
          )}
          <Fade in={!isLoading} appear={false} timeout={500}>
            <iframe
              title="Dashboard v2"
              src={`${
                url ?? process.env.REACT_APP_DASHBOARD
              }/?embed=true&embed_options=hide_loading_screen&embed_options=${dashboard_theme}`}
              // frameBorder="0"
              style={{ display: isLoading ? "none" : "block" }}
              allowFullScreen={true}
              // key={url}
              onLoad={() => setIsLoading(false)}
            ></iframe>
          </Fade>
        </>
      )}
    </>
  );
};

export default HomeV2;
