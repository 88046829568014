// import Home from "./Home";
// import Bills from "./Bills";
//https://codesandbox.io/s/table-w-filter-options-mui-gxbmx
//https://codesandbox.io/s/material-ui-search-table-filter-3n0ip
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Co2TwoToneIcon from "@mui/icons-material/Co2TwoTone";
import MemoryTwoToneIcon from "@mui/icons-material/MemoryTwoTone";
import { NavLink } from "react-router-dom";
import { forwardRef, useState } from "react";
import {
  AppBar,
  Drawer,
  SvgIcon,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
// import Devices from "./Devices";
// import Notification from "./Notification";
// import Settings from "./Settings";
// import GhgScopePage from "./GhgScopePage";
// import SiteDetailsPage from "./SiteDetails";
import SidebarCmpLogo from "../components/utils/SidebarLogo";
import MenuIcon from "@mui/icons-material/Menu";
import "../styles/fontsFile.css";
import { ReactComponent as DashIcon } from "../styles/icons/dashboard.svg";
import { ReactComponent as BillIcon } from "../styles/icons/billicon.svg";
import { ReactComponent as NotiIcon } from "../styles/icons/notification.svg";
import { ReactComponent as SettingIcon } from "../styles/icons/settings.svg";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import ProtectedRoutes from "../components/ProtectedRoutes/ProtectedRoutes";

const drawerWidth = 220;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DrawerSide = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const CustomNavLink = forwardRef((props, ref) => (
  <NavLink
    ref={ref}
    {...props}
    className={({ isActive }) =>
      isActive ? props.className + " Mui-selected" : props.className
    }
    end
  />
));

export default function SideBar() {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [menu, setMenu] = useState();
  const [mobileOpen, setMobileOpen] = useState(false);

  const ismobile = useMediaQuery("(max-width:600px)");

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleListItemClick = (event, index) => {
    setMenu(index);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline sx={{ display: "block" }} />
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          display: { sm: "none" },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography sx={{ fontFamily: "Oswald-Regular", fontWeight: "bold" }}>
            CarbonLnk
          </Typography>
          <Box
            width={"40%"}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <img
              src="/images/carbonlnk-logo.svg"
              alt="carbonlnk-logo"
              width="70"
              height="70"
            />
          </Box>
        </Toolbar>
      </AppBar>

      {ismobile ? (
        <Box component="nav" aria-label="mobile drawer">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            <DrawerHeader
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // ml: open ? 10 : 0,
                backgroundColor: open ? "background.default" : "inherit",
                margin: 1.5,
                borderRadius: 3,
              }}
            >
              {open && (
                <>
                  <img
                    src="/images/carbonlnk-logo.svg"
                    alt="carbonlnk-logo"
                    width="70"
                    height="70"
                  />
                  <Typography
                    sx={{ fontFamily: "Oswald-Regular", fontWeight: "bold" }}
                  >
                    CarbonLnk
                  </Typography>
                </>
              )}
              <IconButton onClick={handleDrawerToggle}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />
            {/* {open && <SidebarCmpLogo open={open} />} */}
            <Box display={"flex"} flexDirection={"column"} height={"100vh"}>
              <List sx={{ flexGrow: 1, marginTop: 2 }}>
                {/* <ListItemButton
                  selected={menu === "Home"}
                  onClick={(_) => {
                    handleListItemClick(_, "Home");
                    handleDrawerToggle();
                  }}
                >
                  <ListItemIcon>
                    <SvgIcon component={DashIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItemButton> */}
                <ListItemButton
                  component={CustomNavLink}
                  to="/dashboard"
                  selected={menu === "HomeV2"}
                  onClick={(_) => handleListItemClick(_, "HomeV2")}
                >
                  <ListItemIcon>
                    <SvgIcon component={DashIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItemButton>
                <ListItemButton
                  selected={menu === "Devices"}
                  onClick={(_) => {
                    handleListItemClick(_, "Devices");
                    handleDrawerToggle();
                  }}
                >
                  <ListItemIcon>
                    <MemoryTwoToneIcon fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary="Devices" />
                </ListItemButton>
                <ListItemButton
                  selected={menu === "Billing"}
                  onClick={(_) => {
                    handleListItemClick(_, "Billing");
                    handleDrawerToggle();
                  }}
                >
                  <ListItemIcon>
                    <SvgIcon component={BillIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Billing" />
                </ListItemButton>
                <Divider />
                <ListItemButton
                  selected={menu === "GHG"}
                  onClick={(_) => {
                    handleListItemClick(_, "GHG");
                    handleDrawerToggle();
                  }}
                >
                  <ListItemIcon>
                    <Co2TwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="GHG" />
                </ListItemButton>
                <Divider />
              </List>
              {/* <List>
      {["Add One", "Add Two", "Spam"].map((text, index) => (
        <ListItem key={text} disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      ))}
    </List> */}
              <List sx={{ marginBottom: 1 }}>
                <ListItemButton
                  selected={menu === "Notification"}
                  onClick={(_) => {
                    handleListItemClick(_, "Notification");
                    handleDrawerToggle();
                  }}
                >
                  <ListItemIcon>
                    <SvgIcon component={NotiIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Notifications" />
                </ListItemButton>
                <ListItemButton
                  selected={menu === "Settings"}
                  onClick={(_) => {
                    handleListItemClick(_, "Settings");
                    handleDrawerToggle();
                  }}
                >
                  <ListItemIcon>
                    <SvgIcon component={SettingIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
                </ListItemButton>
              </List>
            </Box>
          </Drawer>
        </Box>
      ) : (
        <DrawerSide
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
          }}
          open={open}
          PaperProps={{
            sx: {
              backgroundColor: "background.color",
              scrollbarWidth: "none",
            },
          }}
        >
          <DrawerHeader
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // ml: open ? 10 : 0,
              backgroundColor: open ? "background.default" : "inherit",
              margin: 1.5,
              borderRadius: 3,
            }}
          >
            {open && (
              <>
                <img src="/images/carbonlnk-logo.svg" width="60" height="60" />
                <Typography
                  variant="button"
                  sx={{ fontFamily: "Oswald-Regular", fontSize: 14 }}
                >
                  CarbonLnk
                </Typography>
              </>
            )}
            <IconButton onClick={handleDrawerOpen}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          {open && <SidebarCmpLogo open={open} />}
          <Box display={"flex"} flexDirection={"column"} height={"100vh"}>
            <List sx={{ flexGrow: 1 }}>
              {/* <ListItemButton
                component={CustomNavLink}
                to="/"
                selected={menu === "Home"}
                onClick={(_) => handleListItemClick(_, "Home")}
              >
                <ListItemIcon>
                  <SvgIcon component={DashIcon} />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItemButton> */}
              <ListItemButton
                component={CustomNavLink}
                to="/dashboard"
                selected={menu === "HomeV2"}
                onClick={(_) => handleListItemClick(_, "HomeV2")}
              >
                <ListItemIcon>
                  <SvgIcon component={DashIcon} />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItemButton>
              {/* 
          <ListItemButton
            selected={menu === "Home2"}
            onClick={(_) => handleListItemClick(_, "Home2")}
          >
            <ListItemIcon>
              <SpaceDashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard3" />
          </ListItemButton> */}

              {/* <ListItemButton
            selected={menu === "Dashboard"}
            onClick={(_) => handleListItemClick(_, "Dashboard")}
          >
            <ListItemIcon>
              <SettingsRemoteIcon />
            </ListItemIcon>
            <ListItemText
              primary="Device Control"
              
            />
          </ListItemButton> */}
              <ListItemButton
                component={CustomNavLink}
                to="/devices"
                selected={menu === "Devices"}
                onClick={(_) => handleListItemClick(_, "Devices")}
              >
                <ListItemIcon>
                  <MemoryTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="Devices" />
              </ListItemButton>
              <ListItemButton
                component={CustomNavLink}
                to="/billing"
                selected={menu === "Billing"}
                onClick={(_) => handleListItemClick(_, "Billing")}
              >
                <ListItemIcon>
                  <SvgIcon component={BillIcon} />
                </ListItemIcon>
                <ListItemText primary="Billing" />
              </ListItemButton>
              <Divider />
              <ListItemButton
                component={CustomNavLink}
                to="/dashboard/ghg"
                selected={menu === "GHG"}
                onClick={(_) => {
                  handleListItemClick(_, "GHG");
                }}
              >
                <ListItemIcon>
                  <Co2TwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="GHG" />
              </ListItemButton>
              <Divider />
            </List>
            <List>
              <ListItemButton
                component={CustomNavLink}
                to="/sites"
                selected={menu === "SiteDetails"}
                onClick={(_) => {
                  handleListItemClick(_, "SiteDetails");
                }}
              >
                <ListItemIcon>
                  <BusinessRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Site Details" />
              </ListItemButton>
              <ListItemButton
                component={CustomNavLink}
                to="/notification"
                selected={menu === "Notification"}
                onClick={(_) => handleListItemClick(_, "Notification")}
              >
                <ListItemIcon>
                  <SvgIcon component={NotiIcon} />
                </ListItemIcon>
                <ListItemText primary="Notifications" />
              </ListItemButton>
              <ListItemButton
                component={CustomNavLink}
                to="/setting"
                selected={menu === "Settings"}
                onClick={(_) => handleListItemClick(_, "Settings")}
              >
                <ListItemIcon>
                  <SvgIcon component={SettingIcon} />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItemButton>
            </List>
          </Box>
        </DrawerSide>
      )}
      <Box
        component="main"
        sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <ProtectedRoutes />
      </Box>
    </Box>
  );
}
