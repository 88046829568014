import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import UpgradePlan from "./UpgradePlan";

const ProtectedRoutesBilling = ({ module }) => {
  const { userPricingPlan, isLoading, isError, isSuccess } = useSelector(
    (state) => state.auth
  );

  if (userPricingPlan < 2) {
    return <UpgradePlan />;
  }

  return module;
};

export default ProtectedRoutesBilling;
