import { Box, SvgIcon } from "@mui/material";
import {
  Card,
  Divider,
  LineChart,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from "@tremor/react";
import { useGHGYoY } from "../../../features/billSlice";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useRef, useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { ReactComponent as ArrowUpIcon } from "../../../styles/icons/arrow_upIcon.svg";
import { ReactComponent as ArrowDownIcon } from "../../../styles/icons/arrow_downIcon.svg";
import { motion } from "framer-motion";
import LastUpdatedTime from "./LastUpdatedTime";

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

const valueFormatter = (number) =>
  `${Intl.NumberFormat("us").format(number).toString()}`;

const ByYoY = () => {
  const refSC = useRef(null);
  const [dateValueFrom, setDateValueFrom] = useState(dayjs("2020-01-02"));
  const [dateValueTo, setDateValueTo] = useState(dayjs("2027-06-09"));
  const [upDownToggle, setUpDownToggle] = useState(-1);

  const {
    data = [],
    isError: isLoadingError,
    isFetching: isFetchingBills,
    isLoading: isLoadingBills,
    refetch,
  } = useGHGYoY({
    from: dateValueFrom !== "" ? dateValueFrom : dayjs("2020-01-02"),
    to: dateValueTo !== "" ? dateValueTo : dayjs("2027-06-09"),
    byYear: upDownToggle === -1 ? true : false,
  });

  useEffect(() => {
    refetch();
  }, [dateValueFrom, dateValueTo, upDownToggle]);

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider", m: 2 }}>
      <Card className="p-0">
        <div className="border-t border-tremor-border p-6 dark:border-dark-tremor-border">
          <div className="px-4 py-2 flex flex-row justify-between items-center">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className="flex flex-row gap-4 ">
                <DatePicker
                  label="From"
                  className="max-w-xs bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
                  views={upDownToggle > -1 ? ["year"] : ["month", "year"]}
                  maxDate={dayjs(
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth() - 1,
                      1
                    )
                  )}
                  value={dateValueFrom}
                  onChange={(newValue) => setDateValueFrom(newValue)}
                  slotProps={{
                    textField: {
                      size: "small",
                      InputProps: {
                        disableUnderline: true,
                        sx: { "& fieldset": { border: "none" } },
                      },
                      InputLabelProps: {
                        sx: { color: "grey" },
                      },
                    },
                  }}
                  sx={{ paddingX: 0.8, paddingY: 0.6 }}
                />

                <DatePicker
                  label="To"
                  className="max-w-xs bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
                  views={upDownToggle > -1 ? ["year"] : ["month", "year"]}
                  maxDate={dayjs(
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth() - 1,
                      1
                    )
                  )}
                  value={dateValueTo}
                  onChange={(newValue) => setDateValueTo(newValue)}
                  slotProps={{
                    textField: {
                      size: "small",
                      InputProps: {
                        disableUnderline: true,
                        sx: { "& fieldset": { border: "none" } },
                      },
                      InputLabelProps: {
                        sx: { color: "grey" },
                      },
                    },
                  }}
                  sx={{ paddingX: 0.8, paddingY: 0.6 }}
                />
              </div>
            </LocalizationProvider>

            <LastUpdatedTime />
          </div>
          <Divider />

          <TabGroup>
            <div className="flex justify-between">
              <div className="md:flex md:items-center md:justify-between">
                <TabList
                  variant="line"
                  className="w-full rounded-tremor-small md:w-60"
                >
                  {data.map((tab) => (
                    <Tab
                      key={tab.name}
                      className="w-full justify-center ui-selected:text-tremor-content-strong ui-selected:dark:text-dark-tremor-content-strong"
                    >
                      {tab.name}
                    </Tab>
                  ))}
                </TabList>
              </div>

              <div className="md:flex md:items-center md:justify-between gap-1">
                <motion.button
                  className="rounded-full border border-slate-300 p-2.5 text-center text-sm transition-all shadow-sm hover:shadow-lg text-slate-600 hover:text-white hover:bg-slate-800 hover:border-slate-800 focus:text-white focus:bg-slate-800 focus:border-slate-800 active:border-slate-800 active:text-white active:bg-slate-800 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="button"
                  onClick={() =>
                    upDownToggle < 0 && setUpDownToggle((count) => count + 1)
                  }
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <SvgIcon component={ArrowUpIcon} />
                </motion.button>
                <motion.button
                  className="rounded-full border border-slate-300 p-2.5 text-center text-sm transition-all shadow-sm hover:shadow-lg text-slate-600 hover:text-white hover:bg-slate-800 hover:border-slate-800 focus:text-white focus:bg-slate-800 focus:border-slate-800 active:border-slate-800 active:text-white active:bg-slate-800 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="button"
                  onClick={() =>
                    upDownToggle > -1 && setUpDownToggle((count) => count - 1)
                  }
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <SvgIcon component={ArrowDownIcon} />
                </motion.button>
              </div>
            </div>
            <TabPanels>
              {data.map((tab) => (
                <TabPanel key={tab.name}>
                  {/* <ul
                    role="list"
                    className="mt-6 flex flex-wrap gap-x-20 gap-y-10"
                  >
                    {tab.summary.map((item, index) => (
                      <li
                        key={item.name}
                        className="cursor-pointer"
                        onClick={() => {
                          refSC.current?.scrollIntoView({ behavior: "smooth" });
                        }}
                      >
                        <div className="flex items-center space-x-2">
                          <span
                            className={
                              "h-3 w-3 shrink-0 rounded-sm bg-blue-300"
                            }
                            aria-hidden={true}
                          />
                          <p className="font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                            {valueFormatter(item.total)}
                          </p>
                        </div>
                        <p className="whitespace-nowrap text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                          {moment(item.name).format("MMMM-YYYY")}
                        </p>
                      </li>
                    ))}
                  </ul> */}

                  <LineChart
                    data={tab.data}
                    index={"from"}
                    categories={["CO2e", "CO2", "CH4", "N2O"]}
                    colors={["blue", "cyan", "violet", "amber"]}
                    valueFormatter={valueFormatter}
                    showAnimation={true}
                    yAxisWidth={60}
                    className="hidden md:block"
                  />
                  <LineChart
                    data={tab.data}
                    index="from"
                    categories={["CO2e", "CO2", "CH4", "N2O"]}
                    colors={["blue", "cyan", "violet", "amber"]}
                    showAnimation={true}
                    showYAxis={false}
                    valueFormatter={valueFormatter}
                    className="h-72 md:hidden"
                  />
                  {/* <BarChart
                    data={tab.data}
                    index="category"
                    categories={["CO2e", "CO2", "CH4", "N2O"]}
                    colors={["blue", "cyan", "violet", "amber"]}
                    // stack={true}
                    showAnimation={true}
                    showLegend={false}
                    showYAxis={false}
                    valueFormatter={valueFormatter}
                    className="mt-6 h-72 md:hidden"
                  /> */}

                  {/* <h3 className="ml-1 mr-1 font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    Sub Category overview
                  </h3>
                  <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                    Click On Legend to Expand Details.
                  </p>

                  <div ref={refSC}>
                    <BarChart
                      data={tab.data[subCategoryIndex]?.sub_category ?? []}
                      index="sub_category"
                      categories={["CO2e", "CO2", "CH4", "N2O"]}
                      colors={["blue", "cyan", "violet", "amber"]}
                      // stack={true}
                      showAnimation={true}
                      showLegend={false}
                      yAxisWidth={42}
                      valueFormatter={valueFormatter}
                      className="mt-10 hidden h-72 md:block"
                    />
                    <BarChart
                      data={tab.data[subCategoryIndex]?.sub_category ?? []}
                      index="sub_category"
                      categories={["CO2e", "CO2", "CH4", "N2O"]}
                      colors={["blue", "cyan", "violet", "amber"]}
                      // stack={true}
                      showAnimation={true}
                      showLegend={false}
                      showYAxis={false}
                      valueFormatter={valueFormatter}
                      className="mt-6 h-72 md:hidden"
                    />
                  </div> */}
                </TabPanel>
              ))}
            </TabPanels>
          </TabGroup>
        </div>
      </Card>
    </Box>
  );
};

export default ByYoY;
