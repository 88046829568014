import { Box, Typography } from "@mui/material";
import HelpBar from "./HelpBar";
import BTravelList from "./BTravelList";
import { electricityList } from "./sub_cat_list";

const Electricity = () => {
  return (
    <>
      <HelpBar />
      <Box
        bgcolor={"background.default"}
        p={2}
        borderRadius={1}
        mt={4}
        minHeight={"100vh"}
      >
        <Box display={"flex"} flexDirection={"row-reverse"} mb={3}>
          <Typography variant="subtitle2" color="icon">
            {/* Updated on 09 Feb 2024 */}
            Updated Just Now
          </Typography>
        </Box>
        <Typography mb={2}>
          Electricity Consumed From Purchased or Generated Sources.
        </Typography>
        {/* <Typography mb={3}>
          This dosen't include employee commute or company owned or lealesd
          vehicle
        </Typography> */}
        <BTravelList
          category={"Electricity"}
          sub_category={"Electricity type"}
          sub_category_list={electricityList ?? []}
          consumed_value={"Unit Consumed"}
          sub_category_identifier={"Purchase Category"}
          unit={"KWHR"}
        />
      </Box>
    </>
  );
};

export default Electricity;
