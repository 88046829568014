// import MaterialReactTable from "material-react-table";
import "../../styles/not_present.css";
import { useState } from "react";
import { Box } from "@mui/material";
import Loading from "./Loading";
const NotPresent = () => {
  const [isError, setIsError] = useState(false);

  setTimeout(() => {
    setIsError(true);
  }, 4000);

  return (
    <>
      {isError ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <div className="notifications-container">
            <div className="error-alert">
              <div className="flex">
                <div className="flex-shrink-0"></div>
                <div className="error-prompt-container">
                  <p className="error-prompt-heading">No Data Found</p>
                  <div className="error-prompt-wrap">
                    <ul className="error-prompt-list" role="list">
                      <li>Add Data if not Added before</li>
                      <li>or Refresh the page</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      ) : (
        <Box mx={10}>
          <Loading />
        </Box>
      )}
    </>
  );
};

export default NotPresent;
