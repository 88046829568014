import { LinearProgress, Typography } from "@mui/material";

const Loading = () => {
  return (
    <>
      <img src="images/carbonlnk-logo.svg" id="breathing-button"></img>
      <Typography variant="h6">Loading</Typography>
      <LinearProgress color="ternary" />
    </>
  );
};

export default Loading;
