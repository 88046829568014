import { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  MaterialReactTable,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
  // MRT_Se
} from "material-react-table";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  IconButton,
  InputAdornment,
  Slide,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import DeviceSwitch from "./DeviceSwitch";
import { useDispatch } from "react-redux";
import { solarCompare } from "../../features/solarSlice";
import RefreshIcon from "@mui/icons-material/Refresh";
import SnackBarFunction from "../utils/SnackBarFunction";
import OffTime from "../../components/Devices/steeper/steps/Off-Time/OffTime";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { FacilityAddress } from "../Bills/MenuComponents/FacilityAddress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClone,
  faTrash,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as EditAltIcon } from "../../styles/icons/pencil_alt.svg";
import { ReactComponent as TrashIcon } from "../../styles/icons/trash.svg";

import DeviceUploadPopupDialog from "./DeviceUploadPopupDialog";
import { deviceDelete } from "../../features/deviceSlice";
const moment = require("moment");

const DeviceList = ({ rows, getId }) => {
  const [data, setData] = useState(rows);
  const [open, setOpen] = useState({ isOpen: false, dispatchType: "edit" });
  const [rowData, setRowData] = useState([]);
  const dispatch = useDispatch();
  const [openSoalarComparePopUp, setopenSoalarComparePopUp] = useState({
    isopen: false,
    data: {},
  });
  const [openOffDates, setOpenOffDates] = useState({
    isopen: false,
    data: [],
  });
  const [openTopBar, setOpenTopBar] = useState(false);
  const [errors, setErros] = useState({
    loading: false,
    severity: "error",
    message: "Some Error Occured",
  });
  // const [hideText, setHideText] = useState(true);
  // const [hideText1, setHideText1] = useState(true);
  // const [hideText2, setHideText2] = useState(true);
  useEffect(() => {
    if (rows !== undefined) {
      setData(rows);
    }
  }, [rows]);

  // useEffect(() => {
  //   const ids = Object.keys(rowSelection);
  //   getId(ids);
  // }, [rowSelection]);
  const handleClose = () => {
    setOpen({
      ...open,
      isOpen: false,
    });
    getId();
    setRowData([]);
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "deviceNickName",
        id: "name", //id is still required when using accessorFn instead of accessorKey
        header: "Device Name",
        size: 180,
      },
      // {
      //   accessorKey: "department", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
      //   id: "department",
      //   // enableClickToCopy: true,
      //   header: "Department",
      //   size: 200,
      // },
      // {
      //   accessorKey: "lat",
      //   header: "Latitude",
      //   size: 180,
      // },
      // {
      //   accessorKey: "lan",
      //   header: "Longitude",
      //   size: 180,
      // },
      {
        accessorKey: "type",
        header: `Electriticty 
          Type`,
        size: 190,
      },
      // {
      //   accessorKey: "section",
      //   header: "Section",
      //   size: 50,
      // },
      // {
      //   accessorKey: "floor",
      //   header: "Floor",
      //   size: 50,
      // },
      {
        accessorKey: "deviceModel",
        id: "deviceModel", //id is still required when using accessorFn instead of accessorKey
        header: "Device ID",
        size: 30,
        // Cell: ({ renderedCellValue }) => (
        //   <Box
        //     sx={{
        //       display: "flex",
        //       // alignContent: "center",
        //       // justifyContent: "center",
        //     }}
        //   >
        //     {hideText ? "******" : renderedCellValue}
        //     <FontAwesomeIcon
        //       icon={faEyeSlash}
        //       onClick={() => {
        //         setHideText(!hideText);
        //         setTimeout(() => {
        //           setHideText(true);
        //         }, 5000);
        //       }}
        //     />
        //   </Box>
        // ),
      },
      {
        accessorKey: "deviceGateway",
        id: "deviceGateway", //id is still required when using accessorFn instead of accessorKey
        header: "Gateway ID",
        size: 50,
        // Cell: ({ renderedCellValue }) => (
        //   <Box
        //     sx={{
        //       display: "flex",
        //       // alignContent: "center",
        //       // justifyContent: "center",
        //     }}
        //   >
        //     {hideText1 ? "******" : renderedCellValue}
        //     <FontAwesomeIcon
        //       icon={faEyeSlash}
        //       onClick={() => {
        //         setHideText1(!hideText1);
        //         setTimeout(() => {
        //           setHideText1(true);
        //         }, 5000);
        //       }}
        //     />
        //   </Box>
        // ),
      },
      {
        accessorKey: "deviceSerialNumber", //accessorFn used to join multiple data into a single cell
        id: "deviceSerialNumber", //id is still required when using accessorFn instead of accessorKey
        header: "Device Serial",
        size: 50,
        // Cell: ({ renderedCellValue }) => (
        //   <Box
        //     sx={{
        //       display: "flex",
        //       // alignContent: "center",
        //       // justifyContent: "center",
        //     }}
        //   >
        //     {hideText2 ? "******" : renderedCellValue}
        //     <FontAwesomeIcon
        //       icon={faEyeSlash}
        //       onClick={() => {
        //         setHideText2(!hideText);
        //         setTimeout(() => {
        //           setHideText2(true);
        //         }, 5000);
        //       }}
        //     />
        //   </Box>
        // ),
      },
      {
        accessorKey: "deviceChannel",
        id: "deviceChannel",
        header: `Transformer 
        Number`,
        size: 50,
      },
      {
        accessorKey: "location",
        header: "Location",
        size: 50,
        Cell: ({ renderedCellValue }) => (
          <>
            <FacilityAddress renderedCellValue={renderedCellValue} />
          </>
        ),
      },
      {
        accessorKey: "thresholdCost",
        header: "Thresold Value(Cost)",
        Cell: ({ renderedCellValue }) => (
          <>
            {renderedCellValue ? (
              <Typography>£ {renderedCellValue}/-</Typography>
            ) : (
              ""
            )}
          </>
        ),
        size: 50,
      },
      {
        accessorKey: "lekageChannel",
        header: `Leakage
         Channel`,
        size: 50,
      },
      {
        accessorKey: "offTime",
        header: "Off Time",
        enableSorting: false,
        Cell: ({ renderedCellValue }, index) => (
          <>
            <Button
              variant="contained"
              // color="ternary"
              key={index}
              onClick={() => {
                setOpenOffDates({
                  isopen: true,
                  data: renderedCellValue,
                });
              }}
              disableElevation
            >
              View
            </Button>
          </>
        ),
      },
      {
        accessorKey: "trackingstatus",
        id: "trackingstatus", //id is still required when using accessorFn instead of accessorKey
        header: `Tracking 
        Status`,
        size: 50,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row }) => (
          <DeviceSwitch isOn={renderedCellValue === true} data={row.original} />
        ),
      },
      {
        accessorKey: "solarupdate",
        id: "solarupdate", //id is still required when using accessorFn instead of accessorKey
        header: "Solar Update",
        enableSorting: false,
        size: 50,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton
                aria-label="refresh"
                onClick={() => {
                  setopenSoalarComparePopUp({
                    isopen: true,
                    data: row._valuesCache,
                  });
                  // table.setEditingRow(row)
                  // console.log(row._valuesCache);
                }}
              >
                <RefreshIcon />
              </IconButton>
              <Typography sx={{ wordWrap: "break-word" }}>
                {renderedCellValue}
              </Typography>
            </Stack>
          </>
        ),
      },
    ],
    []
  );
  return (
    <Box sx={{ width: "100%", px: 5, maxWidth: "100vw" }}>
      <Box sx={{ width: "100%", mb: 2 }}>
        <MaterialReactTable
          columns={columns}
          data={data}
          enableColumnFilterModes
          enableColumnOrdering
          enableGrouping
          enablePinning
          enableRowActions
          positionActionsColumn={"last"}
          // enableStickyHeader
          // enableRowSelection
          // enableTopToolbar={false} //hide top toolbar
          // enableBottomToolbar={false} //hi
          enableColumnDragging={false}
          // positionGlobalFilter="left"
          getRowId={(row) => row._id} //give each row a more useful id
          // onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
          // state={{ rowSelection }}
          // initialState={{ grouping: ["department"], expanded: true }}
          renderRowActions={({ row }) => (
            <Box
              sx={{
                display: "flex",
                width: 90,
                justifyContent: "space-between",
              }}
            >
              <IconButton
                aria-label="edit"
                size="small"
                sx={{ opacity: "60%" }}
                onClick={() => {
                  setRowData(row.original);
                  setOpen({ isOpen: true, dispatchType: "edit" });
                }}
              >
                <SvgIcon component={EditAltIcon} fontSize={"small"} />
              </IconButton>

              <IconButton
                aria-label="clone"
                size="small"
                sx={{ opacity: "60%" }}
                onClick={() => {
                  setRowData(row.original);
                  setOpen({ isOpen: true, dispatchType: "clone" });
                }}
              >
                <FontAwesomeIcon icon={faClone} size="xs" />
              </IconButton>
              <IconButton
                aria-label="delete"
                color="error"
                size="small"
                sx={{ opacity: "60%" }}
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure you want to delete this device?"
                    )
                  ) {
                    dispatch(deviceDelete({ id: row.id })).then(
                      (onResolved) => {
                        if (onResolved.type === "devices/del/fulfilled") {
                          const id = data.findIndex((i) => i._id === row.id);

                          data.splice(id, 1);
                          setData([...data]);

                          setErros({
                            loading: true,
                            severity: "success",
                            message: "Device Deleted",
                          });
                          setTimeout(() => {
                            setErros({
                              ...errors,
                              loading: false,
                            });
                          }, 2000);
                        } else {
                          setErros({
                            loading: true,
                            severity: "error",
                            message:
                              onResolved.payload?.errors !== undefined
                                ? onResolved.payload?.errors[0].message
                                : "Some Error Occured",
                          });
                          setTimeout(() => {
                            setErros({
                              ...errors,
                              loading: false,
                            });
                          }, 2000);
                        }
                      }
                    );
                  }
                }}
              >
                <SvgIcon component={TrashIcon} fontSize={"small"} />
              </IconButton>
            </Box>
          )}
          positionToolbarAlertBanner="top"
          renderToolbarInternalActions={({ table }) => {
            return (
              <>
                <IconButton onClick={() => setOpenTopBar((prev) => !prev)}>
                  <ChevronLeftIcon />
                </IconButton>
                <Slide
                  direction="up"
                  in={openTopBar}
                  mountOnEnter
                  unmountOnExit
                >
                  {
                    <Box>
                      <MRT_ToggleGlobalFilterButton table={table} />
                      <MRT_ToggleFiltersButton table={table} />
                      <MRT_ShowHideColumnsButton table={table} />
                      <MRT_ToggleDensePaddingButton table={table} />
                      <MRT_ToggleFullScreenButton table={table} />
                    </Box>
                  }
                </Slide>
              </>
            );
          }}
          muiTableHeadCellProps={{
            sx: (theme) => ({
              // py: 2,
              background: theme.palette.table.header,
              color: theme.palette.text.tableHead,
              letterSpacing: "1px",
              // zIndex: 0,//for sticky header
            }),
            // align: "center",
          }}
          muiBottomToolbarProps={{
            sx: (theme) => ({
              // py: 2,
              background: theme.palette.background.default,
              color: theme.palette.text.primary,
            }),
          }}
          muiTopToolbarProps={{
            sx: (theme) => ({
              // py: 2,
              background: theme.palette.background.default,
              color: theme.palette.text.primary,
            }),
          }}
          muiTableContainerProps={{
            sx: {
              borderLeft: "2px solid theme.palette.table.header",
              borderRight: "2px solid theme.palette.table.header",
              borderRadius: 2,
              maxWidth: "100vw",
            },
          }}
          muiTableProps={{
            sx: () => ({
              minWidth: 750,
            }),
            size: "medium",
          }}
          // muiSelectCheckboxProps={{ color: "secondary" }}
          muiTablePaperProps={{
            elevation: 0,
          }}
          muiTableBodyCellProps={{
            sx: (theme) => ({
              color: theme.palette.text.secondary,
              // lineHeight: "175%",
              fontWeight: 400,
            }),
          }}
        />
      </Box>
      <DeviceUploadPopupDialog
        open={open.isOpen}
        handleClose={handleClose}
        rowData={rowData}
        dispatchType={open.dispatchType}
      />
      <CompareModelComponent
        open={openSoalarComparePopUp.isopen}
        data={openSoalarComparePopUp.data}
        onClose={() =>
          setopenSoalarComparePopUp({
            ...openSoalarComparePopUp,
            isopen: false,
          })
        }
      />
      {OffDateViewer(openOffDates, setOpenOffDates)}
      <SnackBarFunction
        isLoadingButton={errors.loading}
        severity={errors.severity}
        message={errors.message}
      />
    </Box>
  );
};

const CompareModelComponent = ({ open, onClose, data }) => {
  const [value, setValue] = useState("forDevice");
  const [startDate, setStartDate] = useState(new Date());
  const [solarHours, setSolarHours] = useState(2.5);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErros] = useState({
    loading: false,
    severity: "error",
    message: "Some Error Occured",
  });
  const [isBuy, setIsBuy] = useState(true);
  const [isLease, setIsLease] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const renderYearContent = (year) => {
    const tooltipText = `Tooltip for year: ${year}`;
    return <span title={tooltipText}>{year}</span>;
  };

  const handleSubmit = (data) => {
    setIsLoading(true);
    dispatch(solarCompare(data)).then((onResolved) => {
      if (onResolved.type === "solar/get/fulfilled") {
        onClose();
        setErros({
          loading: true,
          severity: "success",
          message: "Data Updated",
        });
        setIsLoading(false);
        setTimeout(() => {
          setErros({
            ...errors,
            loading: false,
          });
        }, 2000);
      } else {
        setIsLoading(false);
        setErros({
          loading: true,
          severity: "error",
          message:
            onResolved.payload?.message !== undefined
              ? onResolved.payload?.message
              : "Error Occured, Solar Data not Updated, Try After Sometime",
        });
        setTimeout(() => {
          setErros({
            ...errors,
            loading: false,
          });
        }, 3000);
      }
    });
  };

  const handleBuy = () => {
    setIsBuy(!isBuy);
  };

  const handleLease = () => {
    setIsLease(!isLease);
  };
  const nearTime = [7, 9, 11, 13, 15, 17].find(
    (element) => element >= moment().hours()
  );

  return (
    <>
      <Dialog open={open}>
        <DialogTitle textAlign="center">
          Want to Do Solar Comparision?
        </DialogTitle>
        <DialogContent
          sx={{ backgroundColor: "background.default", borderRadius: 4 }}
        >
          <Stack
            sx={{
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
            }}
          >
            <FormControl
              color="secondary"
              sx={{
                border: "2px dashed rgb(171, 202, 255)",
                borderRadius: 5,
                paddingX: 3,
                pt: 1,
              }}
            >
              <FormLabel id="radio-buttons-group">
                Choose Which Medium
              </FormLabel>
              <RadioGroup
                row
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="forDept"
                  control={<Radio />}
                  label="For Department"
                />
                <FormControlLabel
                  value="forDevice"
                  control={<Radio />}
                  label="For Device"
                  color="secondary"
                />
              </RadioGroup>
              <Typography mt={1} mb={2}>
                {value !== undefined &&
                  (value === "forDept"
                    ? "Comming Soon...!!! Under Development" // `Department is: ${data.department}`
                    : `Device is: ${data.name}`)}
              </Typography>
            </FormControl>

            {value !== "forDept" && (
              <>
                <Box
                  sx={{
                    border: "2px dashed rgb(171, 202, 255)",
                    borderRadius: 5,
                    paddingX: 3,
                    py: 1,
                  }}
                >
                  <Typography>Select Year for you want data* ?</Typography>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    renderYearContent={renderYearContent}
                    showYearPicker
                    dateFormat="yyyy"
                  />
                </Box>
                <TextField
                  label="Avg. Solar Hours*"
                  id="solarAvg"
                  color="icon"
                  value={solarHours}
                  onChange={(event) => {
                    setSolarHours(event.target.value);
                  }}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Hrs</InputAdornment>
                    ),
                    inputProps: { min: 0 },
                  }}
                />
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="ternary"
                        checked={isLease}
                        onChange={() => handleLease()}
                      />
                    }
                    label="Get Leasing Quotation"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isBuy}
                        onChange={() => {
                          handleBuy();
                        }}
                        color="ternary"
                      />
                    }
                    label="Get Buying Quoataion"
                  />
                </FormGroup>
              </>
            )}
          </Stack>
          <Typography>
            Your data process is schedule at :
            {nearTime > 12 ? `${nearTime}:00 PM` : `${nearTime}:00 AM`}
            {/* check for undfiened */}
          </Typography>
        </DialogContent>

        <DialogActions sx={{ p: "1.25rem" }}>
          {value !== "forDept" && (
            <Button
              color="secondary"
              disabled={isLoading}
              variant="contained"
              onClick={() => {
                handleSubmit({
                  deviceId:
                    data.deviceGateway === undefined ||
                    data.deviceGateway === "" ||
                    data.deviceGateway === null
                      ? [`${data.deviceModel}`]
                      : [
                          `${data.deviceGateway}/${data.deviceSerialNumber}/${data.deviceModel}`,
                        ],
                  year: startDate.getFullYear(),
                  ch:
                    data.deviceGateway === undefined ||
                    data.deviceGateway === "" ||
                    data.deviceGateway === null
                      ? [null]
                      : [`${data.deviceChannel}`],
                  solarHours: solarHours,
                  department: value !== undefined && data.department,
                  forDevice: value === "forDept" ? false : true,
                  isLease: isLease,
                  isOwn: isBuy,
                });
              }}
            >
              {isLoading && (
                <CircularProgress size={20} sx={{ marginRight: 2 }} />
              )}
              Done
            </Button>
          )}
          <Button
            disabled={isLoading}
            color="secondary"
            variant="contained"
            onClick={onClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <SnackBarFunction
        isLoadingButton={errors.loading}
        severity={errors.severity}
        message={errors.message}
      />
    </>
  );
};

export default DeviceList;

function OffDateViewer(openOffDates, setOpenOffDates) {
  return (
    <Dialog
      open={openOffDates.isopen}
      onClose={() =>
        setOpenOffDates({
          ...openOffDates,
          isopen: false,
        })
      }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Off Time Schedule"}</DialogTitle>
      <DialogContent>
        <OffTime
          getDates={() => {}}
          offDates={openOffDates?.data ?? []}
          viewOnly={true}
        />
      </DialogContent>
    </Dialog>
  );
}
