import { useEffect, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import DeviceUploadPopupDialog from "./DeviceUploadPopupDialog";
import "../../App.css";
import { Button, useMediaQuery } from "@mui/material";
import { useDispatch } from "react-redux";
import { deviceGet } from "../../features/deviceSlice";
import DeviceList from "./DeviceList";
import NotPresent from "../utils/NotPresent";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Sheet from "react-modal-sheet";
// import ChartLayout from "./ChartLayout";
// import { motion, Variants } from "framer-motion";
import "../../styles/BottonSheetButton.css";
import { useNavigate } from "react-router-dom";

const Sensor_Control = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [rowsData, setRowsData] = useState([]);
  const [open, setOpen] = useState({ isOpen: false });
  // const [bottomSheetOpen, setBottomSheetOpen] = useState({
  //   isOpen: false,
  //   isCard: true,
  // });
  // const ismobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    if (open.isOpen === false) {
      getDevices();
    }
  }, [open.isOpen]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setBottomSheetOpen({ ...bottomSheetOpen, isCard: false });
  //   }, 1000);
  //   return () => {
  //     setBottomSheetOpen({ ...bottomSheetOpen, isCard: true });
  //   };
  // }, [bottomSheetOpen.isCard === true]);

  const getDevices = () => {
    dispatch(deviceGet({})).then((onResolved) => {
      if (onResolved.type === "device/get/fulfilled") {
        setRowsData(onResolved.payload);
      } else if (onResolved.payload === "unauthorized") {
        navigate("/login");
      }
    });
  };

  const handleClose = () => {
    setOpen({
      ...open,
      isOpen: false,
    });
  };

  const getId = () => {
    getDevices();
  };
  return (
    <>
      <>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: 1,
            mb: 3,
          }}
        >
          <>
            <Typography
              sx={{ flex: "1 1 100%", ml: 4, fontWeight: 500 }}
              variant="h6"
              id="tableTitle"
              component="div"
              color={"#8A92A6"}
            >
              Carbonlnk Sensors
              <Typography
                sx={{ flex: "1 1 100%", fontWeight: 400 }}
                variant="subtitle2"
                id="tableSubTitle"
                component="div"
                color={"#8A92A6"}
              >
                ✔️ Managed all your devices here
              </Typography>
            </Typography>

            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              size="large"
              sx={{
                marginRight: { xs: 2, md: 5 },
                alignItems: "center",
                whiteSpace: "nowrap",
                px: 5,
              }}
              color="inherit"
              disableElevation
              onClick={() => {
                setOpen({ isOpen: true });
              }}
            >
              Add New
            </Button>
          </>
        </Toolbar>
        <DeviceUploadPopupDialog
          open={open.isOpen}
          handleClose={handleClose}
          rowData={[]}
          dispatchType={"add"}
        />
      </>

      {rowsData.length <= 0 ? (
        <NotPresent />
      ) : (
        <DeviceList rows={rowsData} getId={getId} />
      )}
      {/* {numSelected.length > 0 &&
        BottomSheet(
          bottomSheetOpen.isOpen,
          setBottomSheetOpen,
          numSelected,
          bottomSheetOpen.isCard
        )} */}
    </>
  );
};

// function BottomSheet(
//   bottomSheetOpen,
//   setBottomSheetOpen,
//   numSelected,
//   cardOpen
// ) {
//   const cardVariants = {
//     offscreen: {
//       y: 300,
//     },
//     onscreen: {
//       y: 50,
//       rotate: -10,
//       transition: {
//         type: "spring",
//         bounce: 0.4,
//         duration: 0.8,
//       },
//     },
//   };

//   function Card({ emoji }) {
//     return (
//       <motion.div
//         className="card-container box overlay"
//         initial="offscreen"
//         whileInView="onscreen"
//         viewport={{ once: true, amount: 0.8 }}
//       >
//         <motion.div className="card" variants={cardVariants}>
//           {emoji}
//         </motion.div>
//       </motion.div>
//     );
//   }
//   return (
//     <>
//       <Sheet
//         isOpen={bottomSheetOpen}
//         onClose={() =>
//           setBottomSheetOpen({ ...bottomSheetOpen, isOpen: false })
//         }
//         style={{
//           maxWidth: "50%",
//           left: "50%",
//           margin: "1rem",
//           borderRadius: "0.8em",
//         }}
//       >
//         <Sheet.Container>
//           <Sheet.Header />
//           <Sheet.Content>
//             <ChartLayout numSelected={numSelected} />
//           </Sheet.Content>
//           <button
//             onClick={() =>
//               setBottomSheetOpen({ ...bottomSheetOpen, isOpen: false })
//             }
//           >
//             Close
//           </button>
//         </Sheet.Container>
//         <Sheet.Backdrop
//           onClick={() =>
//             setBottomSheetOpen({ ...bottomSheetOpen, isOpen: false })
//           }
//         />
//       </Sheet>
//       <div>
//         <Button
//           variant="contained"
//           size="large"
//           className="box"
//           sx={{
//             marginLeft: { xs: 2, md: 5 },
//             alignItems: "center",
//             whiteSpace: "nowrap",
//             px: 5,
//             left: { md: "60%", lg: "75%" },
//             marginRight: { xs: 2, md: 5 },
//           }}
//           onClick={() =>
//             setBottomSheetOpen({ ...bottomSheetOpen, isOpen: true })
//           }
//         >
//           See Realtime Data
//         </Button>
//         {cardOpen && <Card emoji={"📈"} hueA={340} hueB={10} key={"emoji"} />};
//       </div>
//     </>
//   );
// }

export default Sensor_Control;
