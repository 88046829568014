import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ScheduleIcon from "@mui/icons-material/Schedule";
import TimeRange from "./TimeRange";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import SnackBarFunction from "../../../../utils/SnackBarFunction";

const OffTime = ({ getDates, offDates, viewOnly }) => {
  const [checked, setChecked] = useState([]);
  const [DateOffArray, setDateOffArray] = useState(offDates);
  const [errors, setErros] = useState({
    loading: false,
    severity: "error",
    message: "Time is Overlapped",
  });
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const buttonProps = {
    size: isSmallScreen ? "small" : "medium",
  };
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const isTimeOverlap = (schedules, newSchedule) => {
    return schedules.some((schedule) => {
      if (schedule.day === newSchedule.day) {
        const [start1, end1, start2, end2] = [
          schedule.startDate,
          schedule.endDate,
          newSchedule.startDate,
          newSchedule.endDate,
        ];
        return start1 < end2 && end1 > start2;
      }
      return false;
    });
  };

  const getData = ({ startDate, endDate }) => {
    if (
      !isTimeOverlap(DateOffArray, {
        day: checked[checked.length - 1],
        startDate,
        endDate,
      })
    ) {
      setDateOffArray([
        ...DateOffArray,
        { day: checked[checked.length - 1], startDate, endDate },
      ]);
    } else {
      setErros({
        ...errors,
        loading: true,
      });
      setTimeout(() => {
        setErros({
          ...errors,
          loading: false,
        });
      }, 3000);
    }
  };

  useEffect(() => {
    getDates(DateOffArray);
    //   getDates(sortByStartTime(DateOffArray));
  }, [getData]);

  return (
    <>
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
          (value, index) => {
            const labelId = `checkbox-list-label-${value}`;
            return (
              <ListItem
                key={value}
                secondaryAction={
                  checked.indexOf(value) !== -1 && (
                    <TimeRange getData={getData} />
                  )
                }
                disablePadding
                sx={{ margin: 1 }}
              >
                {!viewOnly && (
                  <Button
                    color="info"
                    variant="outlined"
                    startIcon={<ScheduleIcon />}
                    onClick={handleToggle(value)}
                    {...buttonProps}
                  >
                    Show Clock
                  </Button>
                )}
                <Typography key={index} sx={{ marginLeft: 2 }} variant="body2">
                  {value}
                </Typography>

                <ListItemText
                  id={labelId}
                  primary={
                    <>
                      <Box
                        sx={{
                          borderRadius: 2,
                          minWidth: 150,
                          color:
                            // theme.palette.mode === "light"
                            "rgb(55, 65, 81)",
                          // : theme.palette.grey[300],
                          bgcolor: "rgb(255, 255, 255)",
                          boxShadow:
                            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                          maxWidth: 100,
                          padding: 1,
                          marginLeft: 3,
                        }}
                      >
                        {DateOffArray.map((e, eindex) => (
                          <Typography key={eindex} variant="subtitle1">
                            {e.day === value && (
                              <>
                                {e.startDate} - {e.endDate}
                                {!viewOnly && (
                                  <IconButton
                                    aria-label="delete"
                                    onClick={() => {
                                      setDateOffArray(
                                        DateOffArray.filter(
                                          (_, index) => index !== eindex
                                        )
                                      );
                                      //   DateOffArray.splice(eindex, 1);
                                      //   console.log(DateOffArray);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                )}
                              </>
                            )}
                          </Typography>
                        ))}
                      </Box>
                    </>
                  }
                />

                {/* </ListItemButton> */}
              </ListItem>
            );
          }
        )}
      </List>
      <SnackBarFunction
        isLoadingButton={errors.loading}
        severity={errors.severity}
        message={errors.message}
      />
    </>
  );
};

const sortByStartTime = (schedules) => {
  return schedules.sort((a, b) => {
    const timeA = a.startDate.split(":").map(Number);
    const timeB = b.startDate.split(":").map(Number);
    return timeA[0] - timeB[0] || timeA[1] - timeB[1];
  });
};
export default OffTime;
