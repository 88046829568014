import { useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Box, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SnackBarFunction from "../utils/SnackBarFunction";
import { changeLogo } from "../../features/authSlice";

const LogoUpdate = () => {
  const { userLogo } = useSelector((state) => state.auth);
  const [imageUrl, setImageUrl] = useState({
    url: userLogo,
    file: null,
    isOldLogo: true,
  });
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [errors, setErros] = useState({
    loading: false,
    severity: "error",
    message: "Some Error Occured",
  });

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl({ url: reader.result, file: file, isOldLogo: false });
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2}>
        {!imageUrl.isOldLogo && (
          <Button
            variant="contained"
            component="span"
            sx={{ my: 4 }}
            onClick={() => {
              setIsLoadingButton(true);

              dispatch(
                changeLogo({
                  mediaPath: imageUrl.file,
                })
              ).then((onResolved) => {
                if (onResolved.type === "settings/logo/fulfilled") {
                  setImageUrl({ ...imageUrl, isOldLogo: true });
                  setErros({
                    loading: true,
                    severity: "success",
                    message: "Logo Changed",
                  });
                  // navigate("/");
                } else {
                  setErros({
                    loading: true,
                    severity: "error",
                    message:
                      onResolved.payload?.errors !== undefined
                        ? onResolved.payload?.errors[0].message
                        : "Some Error Occured",
                  });
                }
              });
              setTimeout(() => {
                setIsLoadingButton(false);
                setErros({ ...errors, loading: false });
              }, 1000);
            }}
          >
            {isLoadingButton && (
              <CircularProgress
                size={24}
                color="ternary"
                sx={{ marginRight: 2 }}
              />
            )}
            {"Update Logo"}
          </Button>
        )}
        <label htmlFor="upload-image">
          <Button variant="contained" component="span" sx={{ my: 5 }}>
            {imageUrl.isOldLogo ? "Select Logo" : "Re-Select Logo"}
          </Button>
          <input
            id="upload-image"
            hidden
            accept="image/*"
            type="file"
            onChange={handleFileUpload}
          />
        </label>

        {imageUrl.url && (
          <Box
            sx={{
              backgroundColor: "#fde196",
              borderRadius: "50%",
              p: 1,
              width: "5rem",
              height: "5rem",
              overflow: "clip",
            }}
          >
            <img
              src={imageUrl.url}
              alt="Uploaded Logo"
              width="100%"
              height="100%"
              style={{ borderRadius: "50%" }}
            />
          </Box>
        )}
      </Stack>
      <SnackBarFunction
        isLoadingButton={errors.loading}
        severity={errors.severity}
        message={errors.message}
      />
    </>
  );
};

export default LogoUpdate;
