import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import SelectField from "../FormField/SelectField";
import { useField } from "formik";
import { useState } from "react";
import { useSelector } from "react-redux";

export default function LocationSelect(props) {
  const {
    formField: { Location },
  } = props;
  // const sitesDevices = localStorage.getItem("sdklsdifghFOz8956jkhjk");
  const {
    sitesDevices: options,
    isLoading,
    isError,
    isSuccess,
  } = useSelector((state) => state.site_details);

  const [field] = useField(Location);
  const { value: selectedValue } = field;

  const selectedSite = options.find((e) => e.value === selectedValue);

  const [selectNested, setSelectNested] = useState({
    country: selectedSite?.country ?? "",
    facility: selectedSite?.facility ?? "",
    section: selectedSite?.section ?? "",
    floor: selectedSite?.floor ?? "",
  });

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Location Select (Select Department)
      </Typography>

      <Box sx={{ minWidth: 120 }} display="flex" gap={2}>
        <FormControl fullWidth>
          <InputLabel color="icon" id="country-label">
            Country
          </InputLabel>
          <Select
            color="icon"
            labelId="country-label"
            id="country"
            value={selectNested.country}
            label="Country"
            onChange={(event) =>
              setSelectNested({ ...selectNested, country: event.target.value })
            }
          >
            {options
              .map((e) => e.country)
              .filter(
                (value, index, current_value) =>
                  current_value.indexOf(value) === index
              )
              .map((e, index) => (
                <MenuItem value={e} key={index}>
                  {e}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl fullWidth disabled={!selectNested.country}>
          <InputLabel color="icon" id="facility-label">
            Facility
          </InputLabel>
          <Select
            color="icon"
            labelId="facility-label"
            id="facility"
            value={selectNested.facility}
            label="Facility"
            onChange={(event) =>
              setSelectNested({ ...selectNested, facility: event.target.value })
            }
          >
            {options
              .filter((e) => e.country === selectNested.country)
              .map((e) => e.facility)
              .filter(
                (value, index, current_value) =>
                  current_value.indexOf(value) === index
              )
              .map((e, index) => (
                <MenuItem value={e} key={index}>
                  {e}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl fullWidth disabled={!selectNested.facility}>
          <InputLabel color="icon" id="section-label">
            Section
          </InputLabel>
          <Select
            color="icon"
            labelId="section-label"
            id="section"
            value={selectNested.section}
            label="Section"
            onChange={(event) =>
              setSelectNested({ ...selectNested, section: event.target.value })
            }
          >
            {options
              .filter((e) => e.country === selectNested.country)
              .filter((e) => e.facility === selectNested.facility)
              .map((e) => e.section)
              .filter(
                (value, index, current_value) =>
                  current_value.indexOf(value) === index
              )
              .map((e, index) => (
                <MenuItem value={e} key={index}>
                  {e}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl fullWidth disabled={!selectNested.section}>
          <InputLabel color="icon" id="floor-label">
            Floor
          </InputLabel>
          <Select
            color="icon"
            labelId="floor-label"
            id="floor"
            value={selectNested.floor}
            label="Floor"
            onChange={(event) =>
              setSelectNested({ ...selectNested, floor: event.target.value })
            }
          >
            {options
              .filter((e) => e.country === selectNested.country)
              .filter((e) => e.facility === selectNested.facility)
              .filter((e) => e.section === selectNested.section)
              .map((e) => e.floor)
              .filter(
                (value, index, current_value) =>
                  current_value.indexOf(value) === index
              )
              .map((e, index) => (
                <MenuItem value={e} key={index}>
                  {e}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <SelectField
          color="icon"
          name={Location.name}
          label={Location.label}
          data={options
            .filter((e) => e.country === selectNested.country)
            .filter((e) => e.facility === selectNested.facility)
            .filter((e) => e.section === selectNested.section)
            .filter((e) => e.floor === selectNested.floor)
            .filter(
              (value, index, current_value) =>
                current_value.indexOf(value) === index
            )}
          disabled={!selectNested.floor}
          fullWidth
        />
      </Box>
    </>
  );
}
