import LogoutIcon from "@mui/icons-material/Logout";
import { Button, CircularProgress } from "@mui/material";
import SnackBarFunction from "../utils/SnackBarFunction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { logout } from "../../features/authSlice";
import axios from "axios";

const Logout = () => {
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErros] = useState({
    loading: false,
    severity: "error",
    message: "Some Error Occured",
  });
  return (
    <>
      <Button
        sx={{ marginY: 2 }}
        variant="contained"
        endIcon={
          !isLoadingButton ? (
            <LogoutIcon />
          ) : (
            <CircularProgress
              size={24}
              color="ternary"
              sx={{ marginRight: 1 }}
            />
          )
        }
        onClick={() => {
          setIsLoadingButton(true);
          axios
            .request({
              method: "get",
              url: "https://api.ipify.org?format=json",
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: false,
            })
            .then(async (response) => {
              const deviceIP = await response.data.ip;
              dispatch(
                logout({
                  ipofDeviceToRemove: deviceIP,
                })
              ).then((onResolved) => {
                if (onResolved.type === "auth/logout/fulfilled") {
                  localStorage.clear();
                  setErros({
                    loading: true,
                    severity: "success",
                    message: "User Logout",
                  });
                  navigate("/login");
                } else {
                  setErros({
                    loading: true,
                    severity: "error",
                    message:
                      onResolved.payload?.errors !== undefined
                        ? onResolved.payload?.errors[0].message
                        : "Some Error Occured",
                  });
                }
              });
            })
            .catch(() => {
              setErros({
                loading: true,
                severity: "error",
                message: "Some Error Occured",
              });
            });

          setTimeout(() => {
            setIsLoadingButton(false);
          }, 3000);
        }}
      >
        Logout
      </Button>
      <SnackBarFunction
        isLoadingButton={errors.loading}
        severity={errors.severity}
        message={errors.message}
      />
    </>
  );
};

export default Logout;
