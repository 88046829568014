import React, { useState } from "react";
import "../../styles/changePassword.css";
import { useFormik } from "formik";
import { Button, CircularProgress, TextField } from "@mui/material";
import * as yup from "yup";
import SnackBarFunction from "../utils/SnackBarFunction";
import { updatePassword } from "../../features/authSlice";
import { useDispatch } from "react-redux";
import VerifyEmailOtp from "../Login/VerifyEmailOtp";
import {  decryptData } from "../utils/js/encrypt-decrypt";

const validationSchema = yup.object({
  OldPass: yup
    .string("Enter your old password")
    .required("Old Password is required")
    .min(8, "Password should be of minimum 8 characters length"),
  NewPass: yup
    .string("Enter your password")
    .required("Password is required")
    .min(8, "Password should be of minimum 8 characters length")
    .notOneOf([yup.ref("OldPass")], "New Password Shouldn't be same"),
  ConfirmPass: yup
    .string("Enter your password")
    .required("Enter New Password Again")
    .min(8, "Password should be of minimum 8 characters length")
    .oneOf([yup.ref("NewPass")], "Passwords does not match"),
});

const ChangePassword = () => {
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [open, setOpen] = useState(false);
  const [errors, setErros] = useState({
    loading: false,
    severity: "error",
    message: "Some Error Occured",
  }); // set initial state

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      OldPass: "",
      NewPass: "",
      ConfirmPass: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsLoadingButton(true);

      dispatch(
        updatePassword({
          oldPassword: values.OldPass,
        })
      ).then((onResolved) => {
        if (onResolved.type === "auth/password/fulfilled") {
          setErros({
            // ...values, // spreading the unchanged values
            loading: true,
            severity: "success",
            message: onResolved?.payload?.message ?? "OTP send ",
          });
          handleClickOpen();
        } else {
          setErros({
            loading: true,
            severity: "error",
            message:
              onResolved.payload?.errors !== undefined
                ? onResolved.payload?.errors[0].message
                : "Some Error Occured",
          });
        }
      });

      setTimeout(() => {
        actions.setSubmitting(false);
        setIsLoadingButton(false);
        setErros({
          ...values,
          loading: false,
        });
        // actions.resetForm({
        //   values: {
        //     OldPass: "",
        //     NewPass: "",
        //     ConfirmPass: "",
        //   },
        // });
      }, 2000);
    },
    onReset: () => {
      setOpen(false);
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <form className="form" onSubmit={formik.handleSubmit}>
        <TextField
          id="OldPass"
          name="OldPass"
          placeholder="Type your Old Password"
          className="input"
          type="password"
          value={formik.values.OldPass}
          onChange={formik.handleChange}
          error={formik.touched.OldPass && Boolean(formik.errors.OldPass)}
          helperText={formik.touched.OldPass && formik.errors.OldPass}
        />
        <div className="fieldset1">
          <TextField
            id="NewPass"
            name="NewPass"
            placeholder="Type your new password"
            className="input"
            type="password"
            value={formik.values.NewPass}
            onChange={formik.handleChange}
            error={formik.touched.NewPass && Boolean(formik.errors.NewPass)}
            helperText={formik.touched.NewPass && formik.errors.NewPass}
          />
          <TextField
            id="ConfirmPass"
            name="ConfirmPass"
            placeholder="Type and Confirm New Password"
            className="input"
            type="password"
            value={formik.values.ConfirmPass}
            onChange={formik.handleChange}
            error={
              formik.touched.ConfirmPass && Boolean(formik.errors.ConfirmPass)
            }
            helperText={formik.touched.ConfirmPass && formik.errors.ConfirmPass}
          />
        </div>
        <div className="submitCard">
          <Button color="primary" variant="contained" fullWidth type="submit">
            {isLoadingButton ? <CircularProgress /> : "Change Password"}
          </Button>
        </div>
      </form>
      <VerifyEmailOtp
        open={open}
        email={JSON.parse(decryptData(localStorage.getItem("ebgbvbfbjrbgjkkjrjbg")))}
        forCP={true}
        newPassword={formik.values.NewPass}
        onClose={formik.handleReset}
      />
      <SnackBarFunction
        isLoadingButton={errors.loading}
        severity={errors.severity}
        message={errors.message}
      />
    </>
  );
};

export default ChangePassword;
