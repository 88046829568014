import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
// import AssignDashboard from "./pages/AssignDashboard";
import Sidebar from "./pages/Sidebar";
// import Home from "./pages/Home";
import Notification from "./pages/Notification";
import Bills from "./pages/Bills";
import Devices from "./pages/Devices";
import GhgScopePage from "./pages/GhgScopePage";
import SiteDetails from "./pages/SiteDetails";
import Settings from "./pages/Settings";
import Page_404 from "./pages/Page_404";
import HomeV2 from "./pages/Homev2";

function App() {
  return (
    <Routes>
      {/* <Route extact path="/" element={<Sidebar />} /> */}
      <Route extact path="/login" element={<Login />} />
      {/* <Route extact path="/dashboard/assign" element={<AssignDashboard />} /> */}
      <Route path="/" element={<Sidebar />}>
        {/* <Route index element={<Home />} /> */}
        <Route path="/" element={<Navigate to="devices" />} />
        <Route path="dashboard" index element={<HomeV2 />} />
        <Route path="devices" element={<Devices />} />
        <Route path="billing" element={<Bills />} />
        {/* <Route index element={<BTravel />} />
          <Route path="vehicles" element={<BVehicles />} />
          <Route path="electricity" element={<Electricity />} />
          <Route path="fuels" element={<Fuel />} />
          <Route path="materials" element={<Materials />} />
          <Route path="refrigerants" element={<RGas />} />
          <Route path="staffcommuting" element={<StaffCommuting />} />
          <Route path="transport" element={<Transport />} />
          <Route path="waste" element={<Waste />} />
          <Route path="water" element={<Water />} /> */}
        <Route path="dashboard/ghg" element={<GhgScopePage />} />
        <Route path="sites" element={<SiteDetails />} />
        <Route path="notification" element={<Notification />} />
        <Route path="setting" element={<Settings />} />
        <Route path="*" element={<Page_404 />} />
      </Route>
    </Routes>
  );
}

export default App;
