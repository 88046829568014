import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
} from "@mui/material";
import DeviceUpload from "./DeviceUpload";
import { forwardRef } from "react";

const DeviceUploadPopupDialog = ({
  open,
  handleClose,
  dispatchType,
  rowData,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle component="h5">
        <>
          <span className="close" onClick={handleClose}>
            X
          </span>
          <Box sx={{ marginLeft: 6 }}>
            {dispatchType === "add"
              ? "Add new Record"
              : dispatchType === "clone"
              ? "Clone Record"
              : "Edit Record"}
          </Box>
        </>
      </DialogTitle>
      <DialogContent>
        <DeviceUpload
          rowsData={rowData}
          onClose={handleClose}
          dispatchType={dispatchType}
        />
      </DialogContent>
    </Dialog>
  );
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});
export default DeviceUploadPopupDialog;
