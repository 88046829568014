export default {
  formId: "checkoutForm",
  formField: {
    DeviceGateway: {
      name: "DeviceGateway",
      label: "DeviceGateway*",
      requiredErrorMsg: "DeviceGateway is required",
    },
    DeviceSerialNumber: {
      name: "DeviceSerialNumber",
      label: "DeviceSerialNumber*",
      requiredErrorMsg: "DeviceSerialNumber is required",
    },
    DeviceModel: {
      name: "DeviceModel",
      label: "DeviceModel*",
      requiredErrorMsg: "DeviceModel is required",
    },
    DeviceChannel: {
      name: "DeviceChannel",
      label: "DeviceChannel*",
      requiredErrorMsg: "DeviceChannel is required",
    },
    DeviceNickName: {
      name: "DeviceNickName",
      label: "DeviceNickName*",
      requiredErrorMsg: "DeviceNickName is required",
    },
    // Lat: {
    //   name: "Lat",
    //   label: "Latitude*",
    //   requiredErrorMsg: "Latitude is required",
    // },
    // Lang: {
    //   name: "Lang",
    //   label: "Lang*",
    //   requiredErrorMsg: "Longitude",
    //   // invalidErrorMsg: "Lang is not valid (e.g. 70000)",
    // },
    elecTypeValue: {
      name: "elecTypeValue",
      label: "Electricity Types*",
      requiredErrorMsg: "Electricity Types Selection is required",
    },
    // Facility: {
    //   name: "Facility",
    //   label: "Facility*",
    //   requiredErrorMsg: "Facility is required",
    // },
    // Section: {
    //   name: "Section",
    //   label: "Section*",
    //   requiredErrorMsg: "Section is required",
    // },
    // Floor: {
    //   name: "Floor",
    //   label: "Floor*",
    //   requiredErrorMsg: "Floor is required",
    //   // invalidErrorMsg: "Card number is not valid (e.g. 4111111111111)",
    // },
    // Department: {
    //   name: "Department",
    //   label: "Department*",
    //   requiredErrorMsg: "Department is required",
    //   // invalidErrorMsg: "Expiry date is not valid",
    // },
    Location: {
      name: "Location",
      label: "Department *",
      requiredErrorMsg: "Location is required",
      // invalidErrorMsg: "Expiry date is not valid",
    },
    ThresholdCost: {
      name: "ThresholdCost",
      label: "Threshold Cost per day",
    },
    LekageChannel: {
      name: "LekageChannel",
      label: "Lekage Device Channel",
    },
  },
};
