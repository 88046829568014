// import { Colorize } from "@mui/icons-material";
import { createTheme, alpha } from "@mui/material";
import { grey } from "@mui/material/colors";
// import { color, PaletteProps } from "@mui/system";
const choosenTheme = localStorage.getItem("choosenTheme");
const lightTheme = {
  // palette values for light mode
  primary: {
    main: "#FFFFFF",
  },
  secondary: {
    main: "#88c032",
  },
  ternary: {
    main: "#42a5f5",
  },
  button: {
    main: "#1976d2",
    contrastText: "#ffffff",
  },
  mainButton: {
    main: "#ffffff",
    contrastText: "#000000DE",
  },
  background: {
    default: "#FFFFFF",
    paper: "#fafafa",
    color: "#fafafa",
    settings: "#eceff3",
  },
  table: {
    header: "#f4f6fa",
  },
  // divider: amber[200],
  icon: grey[900],
  text: {
    primary: grey[900],
    secondary: grey[800],
    tableHead: "#6e6a6a",
  },
  iconColor: { main: grey[800] },
};

const darkTheme = {
  // palette values for dark mode
  primary: {
    main: "#FFFFFF",
  },
  secondary: {
    main: "#88c032",
  },
  ternary: {
    main: "#42a5f5",
  },
  button: {
    main: "#1976d2",
    contrastText: "#ffffff",
  },
  mainButton: {
    main: "#2f2f2f",
    contrastText: "#ffffff",
  },
  background: {
    default: "#242424",
    paper: "#242424",
    color: "#121212",
    settings: "#eceff3",
  },
  // divider: amber[200],
  table: {
    header: "#404040",
  },

  icon: grey[900],
  text: {
    primary: "#fff",
    secondary: "rgba(255, 255, 255, 0.7)",
  },
  iconColor: { main: grey[800] },
};

const getDesignTokens = (mode) =>
  createTheme({
    colors: {
      icon: {
        primary: mode === "dark" ? grey[50] : grey[900],
      },
    },
    sizes: {
      sidebarWidth: "300px",
    },

    palette: {
      mode,
      ...(mode === "light" ? lightTheme : darkTheme),
    },

    components: {
      MuiListItemButton: {
        // defaultProps: {
        //   selected: true
        // },
        styleOverrides: {
          root: ({ theme }) => ({
            ":hover": {
              backgroundColor: alpha(theme.palette.secondary.main, 0.5),
              color: theme.palette.primary.main,
              ".MuiListItemIcon-root": {
                color: theme.palette.primary.main,
              },
            },
            "&.Mui-selected": {
              "&:hover": {
                backgroundColor: theme.palette.secondary.main,
              },
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.primary.main,
              ".MuiListItemIcon-root": {
                color: theme.palette.primary.main,
              },
              "& .MuiListItemText-primary": {
                color: theme.palette.primary.main,
              },
            },
            ".MuiListItemIcon-root": {
              minWidth: 45,
            },
            borderEndEndRadius: theme.spacing(1),
            borderTopRightRadius: theme.spacing(1),
            padding: "5px 24px",
          }),
        },
      },
      MuiTextField: {
        // defaultProps: {
        //   selected: true
        // },
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: grey[300],
              },
            },
          }),
        },
      },
      MuiRadio: {
        // defaultProps: {
        //   selected: true
        // },
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            "&.Mui-checked": {
              // "&.Mui-focused fieldset": {
              color: "red",
              // },
            },
            color: "ternary",
          }),
        },
      },
      MuiListItemText: {
        // defaultProps: {
        //   selected: true
        // },
        styleOverrides: {
          root: () => ({
            "& .MuiListItemText-primary": {
              // fontFamily: ["Inter", "sans - serif"],
              fontSize: 16,
              fontWeight: 400,
              color: "#8A92A6",
              lineHeight: "175%",
            },
          }),
        },
      },
      MuiStepper: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            "& .MuiStepLabel-root .Mui-active": {
              color: theme.palette.ternary.main,
            },
            "& .MuiStepLabel-root .Mui-completed": {
              color: theme.palette.text.tableHead,
            },
            "& .MuiStepIcon-text": {
              fill: theme.palette.primary.main,
            },
          }),
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            "&:hover .MuiBadge-root[data-mui-internal-clone-element='true']": {
              opacity: 1, // Show the icon on hover
            },
            "& .MuiBadge-root[data-mui-internal-clone-element='true']": {
              opacity: 0, // Hide the icon by default
              transition: "opacity 0.3s ease",
            },
            "&:hover .MuiSvgIcon-root": {
              opacity: 1, // Show icon on hover
            },
            "& .MuiSvgIcon-root": {
              opacity: 0, // Hide icon by default
              transition: "opacity 0.3s ease",
            },
          },
        },
      },
    },
  });

let theme = createTheme(getDesignTokens(choosenTheme ?? "light"));

export default theme;
